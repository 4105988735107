import React from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  Card, 
  CardContent,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { 
  PeopleOutlined, 
  Map as MapIcon,
  Mic as MicIcon,
  AttachMoneyOutlined,
  Info as InfoIcon 
} from '@mui/icons-material';
import { mockStats } from '../data/mockData';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  ArcElement, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  LineElement,
  PointElement,
  Title, 
  Tooltip as ChartTooltip, 
  Legend 
} from 'chart.js';
import { colors } from '../utils/constants';

// Register ChartJS components
ChartJS.register(
  ArcElement, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  LineElement,
  PointElement,
  Title, 
  ChartTooltip, 
  Legend
);

const Dashboard = () => {
  // User stats
  const userChartData = {
    labels: ['Premium', 'Free'],
    datasets: [
      {
        data: [mockStats.users.premium, mockStats.users.free],
        backgroundColor: [colors.primary, colors.secondary],
        hoverBackgroundColor: ['#8a63c8', '#587eeb'],
        borderWidth: 0,
      },
    ],
  };

  // Mind maps creation stats
  const mindMapsChartData = {
    labels: ['With Voice', 'Manual'],
    datasets: [
      {
        data: [
          mockStats.mindMaps.withVoice,
          mockStats.mindMaps.total - mockStats.mindMaps.withVoice,
        ],
        backgroundColor: [colors.primary, colors.info],
        hoverBackgroundColor: ['#8a63c8', '#1e88e5'],
        borderWidth: 0,
      },
    ],
  };

  // Revenue monthly data
  const revenueData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Monthly Revenue ($)',
        data: [8500, 9200, 10500, 9800, 11200, 10800, 12200, 11800, 10500, 12800, 10800, 12400],
        backgroundColor: 'rgba(167, 119, 227, 0.6)', // Parcae's primary color with transparency
        borderColor: colors.primary,
        borderWidth: 2,
      },
    ],
  };

  // User engagement data
  const engagementData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Daily Active Users',
        data: [1700, 1820, 1890, 1950],
        borderColor: colors.primary,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 3,
        tension: 0.4,
      },
      {
        label: 'Mind Maps Created',
        data: [3200, 3700, 4100, 4500],
        borderColor: colors.secondary,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  const StatCard = ({ icon, title, value, subtitle, color }) => (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 2.5, 
        display: 'flex', 
        alignItems: 'center',
        borderRadius: 2,
        boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ 
          borderRadius: '50%', 
          bgcolor: `${color}20`, // Color with low opacity
          p: 1.5,
          mr: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {icon}
      </Box>
      <Box sx={{ zIndex: 1 }}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 500, mb: 0.5 }}>
          {value}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Box>
      <Box 
        sx={{ 
          position: 'absolute',
          right: -15,
          bottom: -15,
          borderRadius: '50%',
          width: 100,
          height: 100,
          bgcolor: `${color}05`, // Very low opacity background
          zIndex: 0
        }} 
      />
    </Paper>
  );

  const ChartCard = ({ title, subtitle, height, children }) => (
    <Card sx={{ height: '100%', boxShadow: '0 2px 10px rgba(0,0,0,0.05)', borderRadius: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </Box>
          <Tooltip title="More information">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ height: height || 300 }}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Dashboard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
        </Typography>
      </Box>
      
      <Grid container spacing={3}>
        {/* Stats Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={<PeopleOutlined sx={{ fontSize: 28, color: colors.primary }} />}
            title="Total Users"
            value={mockStats.users.total.toLocaleString()}
            subtitle={`+${mockStats.users.newThisMonth} this month`}
            color={colors.primary}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={<MapIcon sx={{ fontSize: 28, color: colors.info }} />}
            title="Mind Maps"
            value={mockStats.mindMaps.total.toLocaleString()}
            subtitle={`${mockStats.mindMaps.averagePerUser} avg. per user`}
            color={colors.info}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={<MicIcon sx={{ fontSize: 28, color: colors.success }} />}
            title="Voice Maps"
            value={mockStats.mindMaps.withVoice.toLocaleString()}
            subtitle={`${(mockStats.mindMaps.withVoice / mockStats.mindMaps.total * 100).toFixed(1)}% of total`}
            color={colors.success}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={<AttachMoneyOutlined sx={{ fontSize: 28, color: colors.secondary }} />}
            title="Revenue"
            value={`$${mockStats.revenue.total.toLocaleString()}`}
            subtitle={`$${mockStats.revenue.thisMonth.toLocaleString()} this month`}
            color={colors.secondary}
          />
        </Grid>
        
        {/* Charts */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <ChartCard 
                title="Revenue Trend" 
                subtitle="Monthly revenue for the past year"
              >
                <Bar
                  data={revenueData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: (value) => '$' + value.toLocaleString()
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
              </ChartCard>
            </Grid>
            <Grid item xs={12}>
              <ChartCard 
                title="User Engagement" 
                subtitle="Last 4 weeks activity"
              >
                <Line
                  data={engagementData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }}
                />
              </ChartCard>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Grid container spacing={3} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <ChartCard title="User Distribution" height={205}>
                <Pie 
                  data={userChartData} 
                  options={{ 
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }} 
                />
              </ChartCard>
            </Grid>
            <Grid item xs={12}>
              <ChartCard title="Mind Map Creation Methods" height={205}>
                <Pie 
                  data={mindMapsChartData} 
                  options={{ 
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }} 
                />
              </ChartCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;