/**
 * Scroll animation utility
 * Uses Intersection Observer API to add fade-in animations to elements as they enter the viewport
 */

// Initialize animation for elements with the 'animate-on-scroll' class
export const initScrollAnimations = () => {
  // Options for the observer
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0.1 // Trigger when 10% of the element is visible
  };

  // Create observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      // Add the 'animate-visible' class when the element enters the viewport
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-visible');
        
        // Once the animation is triggered, we don't need to observe this element anymore
        observer.unobserve(entry.target);
      }
    });
  }, options);

  // Get all elements with the 'animate-on-scroll' class
  const elements = document.querySelectorAll('.animate-on-scroll');
  
  // Start observing each element
  elements.forEach(element => {
    // If the element is already in the viewport on page load, animate it immediately
    if (isElementInViewport(element)) {
      setTimeout(() => {
        element.classList.add('animate-visible');
      }, 100); // Small delay to ensure DOM is ready
    } else {
      observer.observe(element);
    }
  });
};

// Helper function to check if an element is in the viewport
function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Export a React hook to use in components
export const useScrollAnimation = () => {
  return {
    initScrollAnimations
  };
};