/**
 * Auth Hook
 * 
 * Connects React components to the auth service and provides
 * a declarative way to use authentication in components.
 */

import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

/**
 * Hook for authentication state and methods
 */
export const useAuth = () => {
  // Initial state from auth service
  const [authState, setAuthState] = useState(() => ({
    ...authService.getAuthState(),
    loading: true,
    needs2FA: false,
    needsSetup2FA: false
  }));
  
  const navigate = useNavigate();

  // Subscribe to auth service changes
  useEffect(() => {
    // Subscribe to auth service updates
    const unsubscribe = authService.subscribe((newState) => {
      setAuthState(current => ({
        ...current,
        ...newState,
        loading: false
      }));
    });
    
    // Initial state update
    setAuthState(current => ({
      ...current,
      ...authService.getAuthState(),
      loading: false
    }));
    
    // Cleanup
    return unsubscribe;
  }, []);

  /**
   * Login with Google
   * @param {string} credential - Google credential
   */
  const loginWithGoogle = useCallback(async (credential) => {
    setAuthState(current => ({ ...current, loading: true }));
    
    const result = await authService.loginWithGoogle(credential);
    
    if (result.success) {
      if (result.needs2FA) {
        setAuthState(current => ({ 
          ...current, 
          needs2FA: true,
          loading: false
        }));
        navigate(`/verify-2fa`);
      } else if (result.needsSetup2FA) {
        setAuthState(current => ({ 
          ...current, 
          needsSetup2FA: true,
          loading: false
        }));
        navigate(`/setup-2fa`);
      } else {
        // Full login successful
        // Check if we have a stored redirect path
        const redirectPath = sessionStorage.getItem('auth_redirect');
        if (redirectPath) {
          sessionStorage.removeItem('auth_redirect');
          navigate(redirectPath);
        } else {
          navigate('/admin/dashboard');
        }
      }
    } else {
      setAuthState(current => ({ ...current, loading: false }));
    }
    
    return result;
  }, [navigate]);

  /**
   * Verify 2FA code
   * @param {string} code - 2FA code
   * @param {string} userId - User ID
   */
  const verify2FA = useCallback(async (code, userId = null) => {
    setAuthState(current => ({ ...current, loading: true }));
    
    const result = await authService.verify2FA(code, userId);
    
    if (result.success) {
      setAuthState(current => ({ 
        ...current, 
        needs2FA: false,
        ...authService.getAuthState(),
        loading: false
      }));
      
      // Check if we have a stored redirect path
      const redirectPath = sessionStorage.getItem('auth_redirect');
      if (redirectPath) {
        sessionStorage.removeItem('auth_redirect');
        navigate(redirectPath);
      } else {
        navigate('/admin/dashboard');
      }
    } else {
      setAuthState(current => ({ ...current, loading: false }));
    }
    
    return result;
  }, [navigate]);

  /**
   * Setup 2FA
   * @param {string} userId - User ID
   */
  const setup2FA = useCallback(async (userId = null) => {
    setAuthState(current => ({ ...current, loading: true }));
    
    const result = await authService.setup2FA(userId);
    
    setAuthState(current => ({ ...current, loading: false }));
    return result;
  }, []);

  /**
   * Confirm 2FA setup
   * @param {string} code - 2FA code
   * @param {string} userId - User ID
   * @param {string} secret - 2FA secret
   */
  const confirmSetup2FA = useCallback(async (code, userId = null, secret = null) => {
    setAuthState(current => ({ ...current, loading: true }));
    
    const result = await authService.confirmSetup2FA(code, userId, secret);
    
    if (result.success) {
      setAuthState(current => ({ 
        ...current, 
        needsSetup2FA: false,
        ...authService.getAuthState(),
        loading: false
      }));
      
      // Check if we have a stored redirect path
      const redirectPath = sessionStorage.getItem('auth_redirect');
      if (redirectPath) {
        sessionStorage.removeItem('auth_redirect');
        navigate(redirectPath);
      } else {
        navigate('/admin/dashboard');
      }
    } else {
      setAuthState(current => ({ ...current, loading: false }));
    }
    
    return result;
  }, [navigate]);

  /**
   * Logout
   */
  const logout = useCallback(() => {
    authService.logout();
    navigate('/login');
  }, [navigate]);

  return {
    ...authState,
    loginWithGoogle,
    verify2FA,
    setup2FA,
    confirmSetup2FA,
    logout
  };
};

export default useAuth;