import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Slider,
  Collapse,
  Tooltip,
  Alert,
  Snackbar,
  TableRow,
  TableCell
} from '@mui/material';
import { 
  Search as SearchIcon,
  FilterList as FilterIcon,
  Visibility as ViewIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { colors } from '../admin/utils/constants';

// Utility imports
import { datePickerStyles } from '../styles/datepickerStyles';
import { formatDate, getRelativeTime } from '../utils/dateUtils';
import { logScale } from '../utils/numberUtils';
import { 
  DEFAULT_COUNTRIES, 
  DEFAULT_LANGUAGES, 
  DEFAULT_SUBSCRIPTION_STATUSES,
  SPENT_SLIDER_MARKS,
  SORTABLE_COLUMNS
} from '../constants/userConstants';

// Component imports
import CustomFilterDropdown from '../components/filters/CustomFilterDropdown';
import DateRangeFilter from '../components/filters/DateRangeFilter';
import SortableTable from '../components/tables/SortableTable';
import StatusChip from '../components/common/StatusChip';
import CountryDisplay from '../components/common/CountryDisplay';
import { LoadingIndicator, ErrorState, EmptyState } from '../components/common/LoadingState';
import SaveSegmentDialog from '../components/users/SaveSegmentDialog';

// Hooks imports
import useFilters from '../hooks/useFilters';
import usePagination from '../hooks/usePagination';
import useSorting from '../hooks/useSorting';

// Services import
import { getUsersService } from '../services/userService';

const UsersPage = () => {
  // UI state
  const [showFilters, setShowFilters] = useState(false);
  const [saveSegmentOpen, setSaveSegmentOpen] = useState(false);
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  
  // Data state
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [metadata, setMetadata] = useState({
    countries: DEFAULT_COUNTRIES,
    languages: DEFAULT_LANGUAGES,
    subscriptionStatuses: DEFAULT_SUBSCRIPTION_STATUSES
  });
  
  // Track if the component has mounted
  const [initialized, setInitialized] = useState(false);
  
  // Single loading state to prevent flashing
  const [initialLoading, setInitialLoading] = useState(true);
  
  // Use custom hooks
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(0, 10);
  const { orderBy, order, handleRequestSort } = useSorting('registrationDate', 'desc');

  const { 
    searchTerm, setSearchTerm,
    startDateRegistered, setStartDateRegistered,
    endDateRegistered, setEndDateRegistered,
    startDateLastVisit, setStartDateLastVisit,
    endDateLastVisit, setEndDateLastVisit,
    spentRange, setSpentRange,
    selectedCountries, setSelectedCountries,
    selectedLanguages, setSelectedLanguages,
    selectedSubscriptionStatuses, setSelectedSubscriptionStatuses,
    buildFiltersObject,
    filtersChanged,
    resetFilterChangeState
  } = useFilters({}, metadata);
  
  // Initialize data on component mount
  useEffect(() => {
    const initializeData = async () => {
      setInitialLoading(true);
      try {
        await fetchMetadata();
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };
    
    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Set initial filter values as soon as metadata is loaded, then fetch users
  useEffect(() => {
    if (metadata && metadata.countries && metadata.languages && metadata.subscriptionStatuses) {
      // Set all filter values to be selected initially
      setSelectedCountries([...metadata.countries]);
      setSelectedLanguages([...metadata.languages]);
      setSelectedSubscriptionStatuses([...metadata.subscriptionStatuses]);
      
      if (!initialized) {
        setInitialized(true);
        
        // Only fetch users once when metadata is ready and filters are set
        fetchUsers().finally(() => {
          // Only turn off initial loading after both metadata and users are loaded
          setInitialLoading(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, initialized]);
  
  // Fetch users when pagination/sorting params change (but not on initial load)
  useEffect(() => {
    if (initialized && !initialLoading) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, orderBy, order, initialized, initialLoading]);
  
  // Fetch metadata from API
  const fetchMetadata = async () => {
    try {
      setLoading(true);
      const result = await getUsersService.getUserMetadata();
      setMetadata(result);
    } catch (err) {
      setError("Failed to fetch metadata: " + err.message);
      setSnackbar({
        open: true,
        message: "Error loading filter options. " + err.message,
        severity: "warning"
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch users from API
  const fetchUsers = async () => {
    try {
      // Only set loading state if not in initial loading (prevents double loading indicators)
      if (!initialLoading) {
        setLoading(true);
      }
      
      const result = await getUsersService.getUsers(page, rowsPerPage, orderBy, order, buildFiltersObject());
      setUsers(result.data);
      setTotal(result.pagination.total);
      setError(null); // Clear any previous errors
      
      return result; // Return for promise chaining
    } catch (err) {
      setError("Error loading users: " + err.message);
      setSnackbar({
        open: true,
        message: "Error fetching users. " + err.message,
        severity: "error"
      });
      
      throw err; // Rethrow for promise chaining
    } finally {
      // Only update loading state if not in initial loading
      if (!initialLoading) {
        setLoading(false);
      }
    }
  };
  
  // Refresh users data
  const handleRefresh = () => {
    fetchUsers();
    resetFilterChangeState();
  };
  
  // Handle segment dialog
  const handleOpenSaveSegment = () => {
    setSaveSegmentOpen(true);
  };
  
  const handleCloseSaveSegment = () => {
    setSaveSegmentOpen(false);
    setSegmentName('');
    setSegmentDescription('');
  };
  
  const handleSaveSegment = async () => {
    try {
      setLoading(true);
      
      const segmentData = {
        name: segmentName,
        description: segmentDescription,
        filterCriteria: buildFiltersObject()
      };
      
      const result = await getUsersService.createSegment(segmentData);
      console.log("Segment created:", result);
      
      handleCloseSaveSegment();
      setSnackbar({
        open: true,
        message: "Segment created successfully!",
        severity: "success"
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to create segment: " + err.message,
        severity: "error"
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Toggle filters visibility
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  
  // Handle search change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  // Snackbar close handler
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Render user row for the table
  const renderUserRow = (user) => (
    <TableRow key={user.id} hover>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{formatDate(user.registrationDate)}</TableCell>
      <TableCell>{getRelativeTime(user.lastAiAttemptDate)}</TableCell>
      <TableCell>{user.totalSpent}</TableCell>
      <TableCell>
        <StatusChip status={user.subscriptionStatus} />
      </TableCell>
      <TableCell>
        <CountryDisplay country={user.country} />
      </TableCell>
      <TableCell>{user.language}</TableCell>
      <TableCell align="right">
        <Tooltip title="View User">
          <IconButton size="small">
            <ViewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  // Generate filter summary for segment dialog
  const filterSummary = (
    <>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Registration:</Box> {startDateRegistered || endDateRegistered ? 
          `${startDateRegistered ? new Date(startDateRegistered).toLocaleDateString() : 'Any'} - ${endDateRegistered ? new Date(endDateRegistered).toLocaleDateString() : 'Any'}` : 
          'Any'}
      </Typography>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Last AI Attempt:</Box> {startDateLastVisit || endDateLastVisit ? 
          `${startDateLastVisit ? new Date(startDateLastVisit).toLocaleDateString() : 'Any'} - ${endDateLastVisit ? new Date(endDateLastVisit).toLocaleDateString() : 'Any'}` : 
          'Any'}
      </Typography>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Tokens Spent:</Box> {Math.round(logScale(spentRange[0]))} - {Math.round(logScale(spentRange[1]))}
      </Typography>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Countries:</Box> {selectedCountries.length === metadata.countries.length ? 'All' : 
          selectedCountries.length <= 3 ? selectedCountries.join(', ') : 
          `${selectedCountries.length} selected`}
      </Typography>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Languages:</Box> {selectedLanguages.length === metadata.languages.length ? 'All' : 
          selectedLanguages.length <= 3 ? selectedLanguages.join(', ') : 
          `${selectedLanguages.length} selected`}
      </Typography>
      <Typography variant="body2" component="div">
        <Box component="span" sx={{ fontWeight: 'medium' }}>Subscription Status:</Box> {selectedSubscriptionStatuses.length === metadata.subscriptionStatuses.length ? 'All' : 
          selectedSubscriptionStatuses.length <= 3 ? selectedSubscriptionStatuses.join(', ') : 
          `${selectedSubscriptionStatuses.length} selected`}
      </Typography>
      {searchTerm && (
        <Typography variant="body2" component="div">
          <Box component="span" sx={{ fontWeight: 'medium' }}>Search Term:</Box> "{searchTerm}"
        </Typography>
      )}
    </>
  );

  return (
    <Box>
      {/* Add styles for date picker */}
      <style>{datePickerStyles}</style>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Users
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search users..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ width: 250 }}
          />
          
          <Tooltip title="Refresh data">
            <IconButton 
              onClick={handleRefresh} 
              disabled={loading}
              sx={{ 
                color: colors.primary,
                '&:hover': { bgcolor: `${colors.primary}15` }
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          
          <Button
            variant={showFilters ? "contained" : "outlined"}
            color="primary"
            size="small"
            startIcon={showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            endIcon={<FilterIcon />}
            onClick={toggleFilters}
            sx={{
              borderRadius: 2,
              px: 2,
              boxShadow: showFilters ? 3 : 0,
              background: showFilters ? colors.gradient : 'transparent',
              '&:hover': {
                background: showFilters ? colors.gradient : `${colors.primary}15`,
                boxShadow: showFilters ? 4 : 0,
              }
            }}
          >
            Filters {total > 0 && users.length !== total && `(${users.length}/${total})`}
          </Button>
        </Box>
      </Box>
      
      {/* Filter section */}
      <Collapse in={showFilters}>
        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', maxWidth: 800, mx: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Filter Options</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRefresh}
                disabled={loading || !filtersChanged}
                startIcon={<RefreshIcon />}
                sx={{
                  borderRadius: 2,
                  px: 2,
                  py: 0.75,
                  '&:hover': { background: `${colors.primary}15` },
                }}
              >
                Apply Filters
              </Button>
              <Button
                variant="contained"
                onClick={handleOpenSaveSegment}
                sx={{
                  background: colors.gradient,
                  borderRadius: 2,
                  px: 2,
                  py: 0.75,
                  '&:hover': { background: colors.gradient, opacity: 0.9 },
                  boxShadow: 2
                }}
              >
                Save as Segment
              </Button>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DateRangeFilter
                startDate={startDateRegistered}
                endDate={endDateRegistered}
                setStartDate={setStartDateRegistered}
                setEndDate={setEndDateRegistered}
                label="Registration Date"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <DateRangeFilter
                startDate={startDateLastVisit}
                endDate={endDateLastVisit}
                setStartDate={setStartDateLastVisit}
                setEndDate={setEndDateLastVisit}
                label="Last AI Attempt"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Tokens Spent: {Math.round(logScale(spentRange[0]))} - {Math.round(logScale(spentRange[1]))}
              </Typography>
              <Box sx={{ px: 2 }}>
                <Slider
                  value={spentRange}
                  onChange={(event, newValue) => setSpentRange(newValue)}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${Math.round(logScale(value))}`}
                  min={0}
                  max={100000}
                  marks={SPENT_SLIDER_MARKS}
                  sx={{
                    color: colors.primary,
                    '& .MuiSlider-thumb': {
                      height: 20,
                      width: 20,
                      backgroundColor: '#fff',
                      border: `2px solid ${colors.primary}`,
                      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px ${colors.primary}30`,
                      },
                    },
                    '& .MuiSlider-valueLabel': {
                      backgroundColor: colors.primary,
                    },
                  }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" gutterBottom>Countries</Typography>
              <CustomFilterDropdown 
                selected={selectedCountries}
                setSelected={setSelectedCountries}
                allOptions={metadata.countries}
                label="Countries"
                renderItem={(country) => (
                  <CountryDisplay country={country} />
                )}
                isCountry={true}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" gutterBottom>Languages</Typography>
              <CustomFilterDropdown 
                selected={selectedLanguages}
                setSelected={setSelectedLanguages}
                allOptions={metadata.languages}
                label="Languages"
                renderItem={(lang) => (
                  <Typography variant="body2">
                    {lang === 'en' ? 'English' : 
                      lang === 'es' ? 'Spanish' : 
                      lang === 'fr' ? 'French' : 
                      lang === 'de' ? 'German' : 
                      lang === 'it' ? 'Italian' : lang}
                  </Typography>
                )}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" gutterBottom>Subscription Status</Typography>
              <CustomFilterDropdown 
                selected={selectedSubscriptionStatuses}
                setSelected={setSelectedSubscriptionStatuses}
                allOptions={metadata.subscriptionStatuses}
                label="Subscription Statuses"
                renderItem={(status) => (
                  <StatusChip status={status} />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      
      <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
        {/* Use initialLoading for the first load to prevent flashing UI states */}
        {initialLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {/* Show loading overlay for subsequent loads */}
            {loading && <LoadingIndicator />}
            
            {error && !loading && (
              <ErrorState error={error} onRetry={handleRefresh} />
            )}
            
            {!loading && !error && users.length === 0 && (
              <EmptyState message="No users found matching the current filters." />
            )}
            
            {!loading && !error && users.length > 0 && (
              <SortableTable
                columns={SORTABLE_COLUMNS}
                data={users}
                orderBy={orderBy}
                order={order}
                onRequestSort={handleRequestSort}
                page={page}
                rowsPerPage={rowsPerPage}
                total={total}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                renderRow={renderUserRow}
              />
            )}
          </>
        )}
      </Paper>
      
      {/* Save Segment Dialog */}
      <SaveSegmentDialog
        open={saveSegmentOpen}
        onClose={handleCloseSaveSegment}
        segmentName={segmentName}
        segmentDescription={segmentDescription}
        onSegmentNameChange={(e) => setSegmentName(e.target.value)}
        onSegmentDescriptionChange={(e) => setSegmentDescription(e.target.value)}
        onSave={handleSaveSegment}
        loading={loading}
        filterSummary={filterSummary}
        userCount={total > 0 ? users.length : 0}
      />
    </Box>
  );
};

export default UsersPage;