import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/LegalPages.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app">
      <Navigation />
      <main className="legal-page">
        <div className="container">
          <div className="legal-content">
            <h1>Privacy Policy</h1>
            <p className="last-updated">Effective Date: 18.02.2025</p>

            <section className="legal-section">
              <p>
                Parcae Labs Ltd ("we," "us," or "our") is committed to protecting the privacy of your information. 
                This Privacy Policy explains how we collect, use, and share your personal data when you use our mind 
                mapping editor application (the "App") and related services (the "Services").
              </p>
            </section>

            <section className="legal-section">
              <h2>1. Information We Collect</h2>
              <p>We collect the following personal data from you:</p>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Profile picture</li>
                <li>Preferred language</li>
                <li>Device and operating system specifications</li>
                <li>App interactions (usage data)</li>
              </ul>
              <p>We do <em>not</em> collect any sensitive personal data or data about children under the age of 13.</p>
            </section>

            <section className="legal-section">
              <h2>2. How We Collect Information</h2>
              <p>We collect your data through the following methods:</p>
              <ul>
                <li>Account registration via email</li>
                <li>OAuth (Google, Apple) authentication</li>
              </ul>
              <p>Users actively opt-in to this data collection during the registration process.</p>
            </section>

            <section className="legal-section">
              <h2>3. How We Use Information</h2>
              <p>We use the collected data for the following purposes:</p>
              <ul>
                <li>Providing and maintaining the Services</li>
                <li>Personalizing your user experience</li>
                <li>Improving the App</li>
                <li>Sending marketing communications (you can opt out of these)</li>
              </ul>
            </section>

            <section className="legal-section">
              <h2>4. Sharing Information with Third Parties</h2>
              <p>We share anonymized app interaction data with the following third-party analytics providers to monitor essential app activities:</p>
              <ul>
                <li>Firebase</li>
                <li>Google Analytics</li>
                <li>Mixpanel</li>
              </ul>
              <p>We do not share any other personal data with third parties except when required by law.</p>
            </section>

            <section className="legal-section">
              <h2>5. Data Storage and Transfer</h2>
              <p>Your data is securely stored in a MongoDB database cluster located in San Paolo, Brazil. MongoDB provides secure data storage solutions.</p>
            </section>

            <section className="legal-section">
              <h2>6. Data Retention</h2>
              <p>You can delete your account and all associated data within the App by using the "delete account" button.</p>
            </section>

            <section className="legal-section">
              <h2>7. Data Security</h2>
              <p>We implement the following security measures to protect your data:</p>
              <ul>
                <li>HTTPS protocol for data encryption in transit</li>
                <li>JWT (JSON Web Token) authorization to protect against unauthorized activity</li>
              </ul>
              <p>We are continuously working to improve our security measures.</p>
            </section>

            <section className="legal-section">
              <h2>8. Your Rights</h2>
              <p>You have the right to request the deletion of your account and associated data. You can exercise this right within the App by using the "delete account" button.</p>
            </section>

            <section className="legal-section">
              <h2>9. Changes to this Privacy Policy</h2>
              <p>This is the current version of our Privacy Policy. We will update this policy as needed, and the latest version will always be available here. We encourage you to review it periodically.</p>
            </section>

            <section className="legal-section">
              <h2>10. Contact Us</h2>
              <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at: support@parcae.info</p>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;