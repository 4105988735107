import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import authService from '../services/authService';
import '../styles/Setup2FAPage.css';

const Setup2FAPage = () => {
  const { setup2FA, confirmSetup2FA, loading } = useAuth();
  const location = useLocation();
  const [secret, setSecret] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);
  const [setupLoading, setSetupLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [initializing, setInitializing] = useState(true);
  
  // Get userId on mount - only once
  useEffect(() => {
    
    // Then try from service
    const storedUserId = authService.getTempUserId();
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setError('No user ID found. Please restart the login process.');
    }
    
    setInitializing(false);
  }, [location.search]);
  
  // Fetch setup data when userId is available
  useEffect(() => {
    const fetchSetupData = async () => {
      if (!userId) return;
      
      try {
        setSetupLoading(true);
        const result = await setup2FA(userId);
        
        if (result.success) {
          setSecret(result.secret);
          setQrCodeUrl(result.qrCodeUrl);
        } else {
          setError(result.message || 'Failed to set up 2FA');
        }
      } catch (err) {
        setError('Could not set up 2FA. Please try again.');
      } finally {
        setSetupLoading(false);
      }
    };
    
    fetchSetupData();
  }, [userId, setup2FA]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    setError('');
    setVerifying(true);
    
    if (!userId) {
      setError('No user ID found. Please restart the login process.');
      setVerifying(false);
      return;
    }
    
    if (!secret) {
      setError('No secret key available. Please restart the setup process.');
      setVerifying(false);
      return;
    }
    
    try {
      const result = await confirmSetup2FA(code, userId, secret);
      
      if (!result.success) {
        setError(result.message || 'Verification failed');
      }
    } catch (err) {
      setError('Verification failed. Please try again.');
    } finally {
      setVerifying(false);
    }
  };

  if (initializing || setupLoading) {
    return (
      <div className="setup-2fa-container">
        <div className="setup-2fa-box">
          <h1>Setting Up Two-Factor Authentication</h1>
          <div className="loading">
            {initializing ? 'Loading authentication details...' : 'Generating your 2FA secret...'}
          </div>
        </div>
      </div>
    );
  }

  if (!userId) {
    return (
      <div className="setup-2fa-container">
        <div className="setup-2fa-box">
          <h1>Setting Up Two-Factor Authentication</h1>
          <div className="error-container">
            <p>Unable to find your authentication session.</p>
            <p>Please restart the login process.</p>
          </div>
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className="setup-2fa-container">
      <div className="setup-2fa-box">
        <h1>Set Up Two-Factor Authentication</h1>
        <p>To secure your admin account, please set up two-factor authentication (2FA).</p>
        
        <div className="steps-container">
          <div className="step">
            <h3>1. Install an Authenticator App</h3>
            <p>Download Google Authenticator or Authy on your mobile device if you don't already have it.</p>
          </div>
          
          <div className="step">
            <h3>2. Scan this QR Code</h3>
            <div className="qr-code-container">
              {qrCodeUrl ? (
                <QRCodeSVG value={qrCodeUrl} size={200} />
              ) : (
                <div className="error-message">Could not generate QR code.</div>
              )}
            </div>
          </div>
          
          <div className="step">
            <h3>3. Or Enter the Secret Key</h3>
            <div className="secret-key">••••••••••••••••</div>
            <p>If you can't scan the QR code, enter this secret key manually into your authenticator app.</p>
          </div>
          
          <div className="step">
            <h3>4. Verify Setup</h3>
            <p>Enter the 6-digit code from your authenticator app to verify setup.</p>
            
            <form onSubmit={handleConfirm}>
              <div className="code-input-container">
                <input
                  type="text"
                  placeholder="6-digit code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={6}
                  pattern="[0-9]{6}"
                  required
                />
              </div>
              
              <button 
                type="submit" 
                className="verify-button"
                disabled={loading || verifying}
              >
                {loading || verifying ? 'Verifying...' : 'Verify and Complete Setup'}
              </button>
            </form>
          </div>
        </div>
        
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default Setup2FAPage;