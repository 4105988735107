import React from 'react';
import '../styles/BrandLogos.css';

// Import brand logos
import logo1 from '../assets/images/brandlogo-kotlin.png';
import logo2 from '../assets/images/brandlogo-firebase.png';
import logo3 from '../assets/images/brandlogo-figma.png';
import logo4 from '../assets/images/brandlogo-jetbrains.png';
import logo5 from '../assets/images/brandlogo-mongodb.png';
const BrandLogos = () => {
  const logos = [
    {
      id: 1,
      src: logo1,
      alt: 'Acme Corporation Logo'
    },
    {
      id: 2,
      src: logo2,
      alt: 'TechSolutions Logo'
    },
    {
      id: 3,
      src: logo3,
      alt: 'InnovateCorp Logo'
    },
    {
      id: 4,
      src: logo4,
      alt: 'NextGen Systems Logo'
    },
    {
      id: 5,
      src: logo5,
      alt: 'FutureWave Logo'
    }
  ];
  
  // Duplicate logos array to create continuous scroll effect
  const duplicatedLogos = [...logos, ...logos];

  return (
    <section className="brand-logos-section">
      <div className="container">
        <div className="section-header">
          <h2 className="section-title">Technologies we use</h2>
          <p className="section-subtitle">and enjoy</p>
        </div>
      </div>
      
      <div className="logos-container" aria-labelledby="partners-heading">
        <h3 id="partners-heading" className="visually-hidden">Our Partner Companies</h3>
        <div className="logos-scroll">
          {duplicatedLogos.map((logo, index) => (
            <div key={`${logo.id}-${index}`} className="logo-item">
              <img src={logo.src} alt={logo.alt} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandLogos;