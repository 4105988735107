// Advanced storage manager that operates independently of React's rendering cycle
// Uses in-memory cache to avoid repeated localStorage accesses during React StrictMode double renders

// Create a memory cache to avoid repeated localStorage access
const memoryCache = new Map();

// Singleton instance to track if we've already initialized from localStorage
let initialized = false;

// Initialize cache from localStorage
const initializeCache = () => {
  if (initialized) return;
  
  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const value = localStorage.getItem(key);
        if (value) {
          memoryCache.set(key, value);
        }
      }
    }
    console.log('Storage cache initialized with entries:', memoryCache.size);
    initialized = true;
  } catch (error) {
    console.error('Error initializing storage cache:', error);
  }
};

// Initialize on module load
initializeCache();

// Store a value with the given key in both localStorage and memory cache
export const setStorageItem = (key, value) => {
  try {
    // Store both the value and a timestamp
    const item = {
      value,
      timestamp: Date.now()
    };
    
    // Convert to JSON string
    const serialized = JSON.stringify(item);
    
    // Update localStorage and memory cache
    localStorage.setItem(key, serialized);
    memoryCache.set(key, serialized);
    
    console.debug(`Stored ${key} in storage system:`, value);
    return true;
  } catch (error) {
    console.error(`Error storing ${key} in storage system:`, error);
    return false;
  }
};

// Get a value from memory cache first, then localStorage as fallback
export const getStorageItem = (key, timeoutMinutes = 30) => {
  try {
    // Try to get from memory cache first
    let rawValue = memoryCache.get(key);
    
    // If not in memory cache, try localStorage
    if (!rawValue) {
      rawValue = localStorage.getItem(key);
      // If found in localStorage but not in memory, update memory cache
      if (rawValue) {
        memoryCache.set(key, rawValue);
      } else {
        console.debug(`No ${key} found in storage system`);
        return null;
      }
    }
    
    // First, try to parse as JSON
    try {
      const item = JSON.parse(rawValue);
      
      // If it's one of our wrapped storage items with a timestamp
      if (item && typeof item === 'object' && item.hasOwnProperty('value') && item.hasOwnProperty('timestamp')) {
        // Check if the item is expired
        if (timeoutMinutes > 0) {
          const now = Date.now();
          const ageInMinutes = (now - item.timestamp) / (1000 * 60);
          
          if (ageInMinutes > timeoutMinutes) {
            console.debug(`${key} in storage is expired (${ageInMinutes.toFixed(1)} minutes old)`);
            removeStorageItem(key);
            return null;
          }
        }
        
        console.debug(`Retrieved ${key} from storage system:`, item.value);
        return item.value;
      } else {
        // It's a regular JSON object, not one of our wrapped items
        console.debug(`Retrieved ${key} from storage system (JSON):`, item);
        return item;
      }
    } catch (jsonError) {
      // Not valid JSON, so just return the raw string
      console.debug(`Retrieved ${key} from storage system (string):`, rawValue);
      return rawValue;
    }
  } catch (error) {
    console.error(`Error retrieving ${key} from storage system:`, error);
    return null;
  }
};

// Remove a value from both localStorage and memory cache
export const removeStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
    memoryCache.delete(key);
    console.debug(`Removed ${key} from storage system`);
    return true;
  } catch (error) {
    console.error(`Error removing ${key} from storage system:`, error);
    return false;
  }
};