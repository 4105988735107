import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './styles/App.css';

// Pages
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import AccountDeletion from './pages/AccountDeletion';
import Unsubscribe from './pages/Unsubscribe';
import LoginPage from './pages/LoginPage';
import Setup2FAPage from './pages/Setup2FAPage';
import Verify2FAPage from './pages/Verify2FAPage';

// Admin App
import AdminApp from './admin/AdminApp';

// Context
import { AuthProvider } from './context/AuthContext';

function App() {
  // Use Google OAuth client ID from environment variable
  // Fall back to a known working client ID if environment variable isn't set
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "520672284236-e5e2ha9b1rtaoboenikpd0498abao8nq.apps.googleusercontent.com";
  
  
  // We don't need to handle redirects anymore as it's handled by .htaccess
  
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/account-deletion" element={<AccountDeletion />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/setup-2fa" element={<Setup2FAPage />} />
            <Route path="/verify-2fa" element={<Verify2FAPage />} />
            
            {/* Admin routes - use wildcard to enable nested routing */}
            <Route path="/admin/*" element={<AdminApp />} />
            
            {/* For direct access to dashboard (makes debugging easier) */}
            <Route path="/admin-dashboard" element={
              <Navigate to="/admin/dashboard" replace />
            } />
            
            {/* 404 route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;