import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Collapse
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  Check as CheckIcon,
  Error as ErrorIcon
} from '@mui/icons-material';

import ImageItem from './ImageItem';
import { extractImageSources, replaceImageSources, isValidExternalUrl } from './imageUtils';

/**
 * Main image uploader component
 */
const ImageUploader = ({ htmlContent, onChange, onValidationChange }) => {
  const [images, setImages] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const prevHtmlContentRef = useRef('');
  
  // Extract images when HTML content changes (dependency array doesn't include images)
  useEffect(() => {
    // Skip if HTML hasn't changed or is empty
    if (prevHtmlContentRef.current === htmlContent || !htmlContent) {
      return;
    }
    
    // Clean up the HTML before extraction
    // This prevents any issues with accumulation of BR tags
    const cleanHtml = htmlContent.replace(/<br\s*\/?>\s*<br\s*\/?>/gi, '<br>');
    
    prevHtmlContentRef.current = cleanHtml;
    const extractedImages = extractImageSources(cleanHtml);
    
    // Preserve upload status for existing images
    setImages(prevImages => {
      const updatedImages = extractedImages.map(newImg => {
        // First try to match by id (which includes position information)
        const existingImgById = prevImages.find(img => img.id === newImg.id);
        if (existingImgById) {
          return {
            ...newImg,
            file: existingImgById.file,
            newSrc: existingImgById.newSrc,
            uploaded: existingImgById.uploaded,
            error: existingImgById.error
          };
        }
        
        // Fallback to matching by original source (legacy behavior)
        const existingImgBySrc = prevImages.find(img => img.originalSrc === newImg.originalSrc && 
                                                      img.imgIndex === newImg.imgIndex);
        if (existingImgBySrc) {
          return {
            ...newImg,
            file: existingImgBySrc.file,
            newSrc: existingImgBySrc.newSrc,
            uploaded: existingImgBySrc.uploaded,
            error: existingImgBySrc.error
          };
        }
        
        return newImg;
      });
      
      return updatedImages;
    });
  }, [htmlContent]); // Only depend on htmlContent
  
  // Keep track of the last HTML content that was used to update the parent
  const lastHtmlUpdateRef = useRef(htmlContent);
  
  // Store previous validation state to prevent unnecessary updates
  const prevValidationRef = useRef({ valid: true, pendingUploads: false, hasErrors: false });
  
  // Validate images and update parent component
  useEffect(() => {
    // Skip if images array is empty
    if (images.length === 0) {
      if (onValidationChange && 
          (prevValidationRef.current.pendingUploads || prevValidationRef.current.hasErrors)) {
        const validationState = { valid: true, pendingUploads: false, hasErrors: false };
        prevValidationRef.current = validationState;
        onValidationChange(validationState);
      }
      return;
    }
    
    const hasImages = images.length > 0;
    const allUploaded = hasImages && images.every(img => img.uploaded || isValidExternalUrl(img.originalSrc));
    const hasErrors = images.some(img => img.error);
    
    // Create validation state
    const validationState = {
      valid: !hasImages || (allUploaded && !hasErrors),
      pendingUploads: hasImages && !allUploaded && !hasErrors,
      hasErrors
    };
    
    // Debug logging
    console.log('Image validation:', {
      hasImages,
      allUploaded,
      hasErrors,
      validationState,
      images: images.map(img => ({
        src: img.originalSrc,
        uploaded: img.uploaded,
        isExternal: isValidExternalUrl(img.originalSrc)
      }))
    });
    
    // Notify parent component about validation status if it changed
    if (onValidationChange && 
        (prevValidationRef.current.valid !== validationState.valid || 
         prevValidationRef.current.pendingUploads !== validationState.pendingUploads ||
         prevValidationRef.current.hasErrors !== validationState.hasErrors)) {
      prevValidationRef.current = validationState;
      onValidationChange(validationState);
    }
    
    // Update HTML content with new image sources only if necessary
    // Check if any images have been modified since the last update
    const hasModifiedSources = images.some(img => 
      img.newSrc && img.newSrc !== img.originalSrc
    );
    
    // Only update HTML if we have modified images and an onChange handler
    if (onChange && hasModifiedSources) {
      // Use the cached HTML if available, otherwise use the current HTML
      const sourceHtml = htmlContent;
      const updatedHtml = replaceImageSources(sourceHtml, images);
      
      // Only update if the content has actually changed
      if (updatedHtml !== lastHtmlUpdateRef.current) {
        console.log('Updating HTML content with new image sources');
        lastHtmlUpdateRef.current = updatedHtml;
        onChange(updatedHtml);
      }
    }
  }, [images, onChange, onValidationChange, htmlContent]);  // Keep all dependencies
  
  // Handle image upload
  const handleImageUpload = (index, updatedImage) => {
    const updatedImages = [...images];
    updatedImages[index] = updatedImage;
    setImages(updatedImages);
  };
  
  // Reset image to original
  const handleImageReset = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = {
      ...updatedImages[index],
      file: null,
      newSrc: null,
      uploaded: false,
      error: null
    };
    setImages(updatedImages);
  };
  
  // If no images found, return null
  if (images.length === 0) {
    return null;
  }
  
  // Count stats
  const uploadedCount = images.filter(img => img.uploaded || isValidExternalUrl(img.originalSrc)).length;
  const errorCount = images.filter(img => img.error).length;
  
  return (
    <Box sx={{ mb: 3 }}>
      <Paper
        sx={{
          p: 2,
          mb: 2,
          borderRadius: 2,
          backgroundColor: '#f8f9fa'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ArrowUpwardIcon
              sx={{
                mr: 1,
                transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s'
              }}
            />
            <Typography variant="subtitle1">
              Email Images ({uploadedCount}/{images.length} uploaded)
            </Typography>
          </Box>
          
          <Box>
            {errorCount > 0 && (
              <Tooltip title={`${errorCount} images have errors`}>
                <IconButton size="small" color="error">
                  <ErrorIcon />
                </IconButton>
              </Tooltip>
            )}
            
            {uploadedCount === images.length && errorCount === 0 && (
              <Tooltip title="All images uploaded">
                <IconButton size="small" color="success">
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        
        <Collapse in={expanded}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Upload or replace images found in your HTML. Email templates can only be saved after all images are properly uploaded.
            </Typography>
            
            <Box sx={{ mt: 2 }}>
              {images.map((image, index) => (
                <ImageItem
                  key={image.id || index}
                  image={image}
                  index={index}
                  onUpload={handleImageUpload}
                  onReset={handleImageReset}
                />
              ))}
            </Box>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default ImageUploader;