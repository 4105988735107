import React from 'react';
import { Box, CircularProgress, Alert, Button, Typography } from '@mui/material';
import { colors } from '../../admin/utils/constants';

export const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
    <CircularProgress sx={{ color: colors.primary }} />
  </Box>
);

export const ErrorState = ({ error, onRetry }) => (
  <Box sx={{ p: 4, textAlign: 'center' }}>
    <Alert severity="error" sx={{ display: 'inline-flex', textAlign: 'left' }}>
      {error}
      {onRetry && (
        <Button 
          variant="text" 
          size="small" 
          onClick={onRetry}
          sx={{ ml: 2 }}
        >
          Retry
        </Button>
      )}
    </Alert>
  </Box>
);

export const EmptyState = ({ message }) => (
  <Box sx={{ p: 4, textAlign: 'center' }}>
    <Typography variant="body1" color="text.secondary">
      {message || "No data found."}
    </Typography>
  </Box>
);
