import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/LegalPages.css';

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app">
      <Navigation />
      <main className="legal-page">
        <div className="container">
          <div className="legal-content">
            <h1>Terms of Service</h1>
            <p className="last-updated">Effective Date: 18.02.2025</p>

            <section className="legal-section">
              <p><strong>Welcome to Parcae Labs Ltd!</strong></p>
              
              <p>
                These Terms of Service ("Terms") govern your access to and use of the Parcae Labs Ltd mind mapping editor 
                application (the "App"), including any related services, features, and content (collectively, the "Services") 
                provided by Parcae Labs Ltd ("we," "us," or "our"). Parcae Labs Ltd is a company registered in the United Kingdom, 
                with a registered office at London (W1W 5PF) Office, 167-169 Great Portland Street, 5th Floor, London, W1W 5PF.
              </p>
              
              <p><strong>By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, do not access or use the Services.</strong></p>
            </section>

            <section className="legal-section">
              <h2>1. Account Creation and Use:</h2>
              <p>
                You may need to create an account to access certain features of the App. You are responsible for maintaining 
                the confidentiality of your account credentials and for all activities that occur under your account. 
                You must provide accurate and complete information when creating your account. You agree to notify us 
                immediately of any unauthorized access to or use of your account.
              </p>
            </section>

            <section className="legal-section">
              <h2>2. User Content:</h2>
              <p>
                The App allows you to create and/or upload content, including text and images ("User Content"). 
                You are solely responsible for your User Content. You represent and warrant that you own or have 
                the necessary rights to use your User Content and that your User Content does not infringe upon 
                the intellectual property rights or other rights of any third party.
              </p>
              <p>
                You retain all rights in your User Content, but you grant us a non-exclusive, worldwide, royalty-free 
                license to use, reproduce, modify, and distribute your User Content solely for the purpose of providing 
                and improving the Services.
              </p>
            </section>

            <section className="legal-section">
              <h2>3. In-App Purchases and Subscriptions:</h2>
              <p>
                We offer in-app purchases and subscription plans for access to certain features or content within the App. 
                All purchases and subscriptions are governed by the applicable app store's terms and conditions. 
                We do not offer free trials for subscriptions. Subscription fees will be charged to your chosen payment 
                method at the beginning of each subscription period. You can manage your subscriptions through your app store account.
              </p>
            </section>

            <section className="legal-section">
              <h2>4. Intellectual Property:</h2>
              <p>
                All content included in the Services, including but not limited to our logo, visual design, trademarks, 
                software, and code, is the exclusive property of Parcae Labs Ltd and is protected by copyright, trademark, 
                and other intellectual property laws. You may not use our content without our prior written consent.
              </p>
            </section>

            <section className="legal-section">
              <h2>5. Feedback and Suggestions:</h2>
              <p>
                We welcome your feedback and suggestions regarding the Services. However, you acknowledge and agree that 
                we may use any feedback or suggestions you provide without any obligation to compensate you or provide you with credit.
              </p>
            </section>

            <section className="legal-section">
              <h2>6. Promotions, Contests, and Sweepstakes:</h2>
              <p>
                We may, from time to time, offer promotions, contests, or sweepstakes through the Services. 
                These promotions, contests, and sweepstakes will be subject to separate rules and regulations, 
                which we will make available to you.
              </p>
            </section>

            <section className="legal-section">
              <h2>7. Disclaimer of Warranties:</h2>
              <p>
                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, 
                EITHER EXPRESS OR IMPLIED. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.
              </p>
            </section>

            <section className="legal-section">
              <h2>8. Limitation of Liability:</h2>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, 
                CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, 
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
            </section>

            <section className="legal-section">
              <h2>9. Governing Law:</h2>
              <p>
                These Terms will be governed by and construed in accordance with the laws of England and Wales.
              </p>
            </section>

            <section className="legal-section">
              <h2>10. Contact Us:</h2>
              <p>
                If you have any questions regarding these Terms, please contact us at:
              </p>
              <p>
                Email: support@parcae.info
              </p>
            </section>

            <section className="legal-section">
              <h2>11. Changes to these Terms:</h2>
              <p>
                We may update these Terms from time to time. We will notify you of any material changes by posting 
                the updated Terms on the App or by other means. Your continued use of the Services following the 
                posting of the updated Terms constitutes your acceptance of the changes.
              </p>
            </section>

            <section className="legal-section">
              <h2>12. Entire Agreement:</h2>
              <p>
                These Terms constitute the entire agreement between you and Parcae Labs Ltd regarding the Services 
                and supersede all prior agreements and understandings.
              </p>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Terms;