export const getCountryFlag = (country) => {
  switch (country) {
    case 'US': return '🇺🇸';
    case 'UK': return '🇬🇧';
    case 'FR': return '🇫🇷';
    case 'DE': return '🇩🇪';
    case 'ES': return '🇪🇸';
    case 'IT': return '🇮🇹';
    case 'JP': return '🇯🇵';
    case 'CN': return '🇨🇳';
    case 'IN': return '🇮🇳';
    case 'BR': return '🇧🇷';
    case 'CA': return '🇨🇦';
    case 'AU': return '🇦🇺';
    case 'RU': return '🇷🇺';
    case 'KR': return '🇰🇷';
    case 'MX': return '🇲🇽';
    case 'SE': return '🇸🇪';
    case 'FI': return '🇫🇮';
    case 'CH': return '🇨🇭';
    case 'NL': return '🇳🇱';
    case 'BE': return '🇧🇪';
    default: return '🌍';
  }
};

export const getLanguageName = (langCode) => {
  switch (langCode) {
    case 'en': return 'English';
    case 'es': return 'Spanish';
    case 'fr': return 'French';
    case 'de': return 'German';
    case 'it': return 'Italian';
    default: return langCode;
  }
};
