import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/Unsubscribe.css';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Check for email in URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // In a real app, this would send a request to the backend
    setIsSubmitted(true);
  };

  return (
    <div className="app">
      <Navigation />
      <main className="unsubscribe-page">
        <div className="container">
          <div className="unsubscribe-content">
            <h1>Unsubscribe from Emails</h1>
            <p className="unsubscribe-intro">
              We're sorry to see you go. Please confirm your email address below to unsubscribe from our mailing list.
            </p>

            {isSubmitted ? (
              <div className="submission-success">
                <h2>Unsubscribed Successfully</h2>
                <p>
                  You have been successfully unsubscribed from our mailing list. You will no longer receive emails from Parcae.
                </p>
                <p>
                  If you change your mind, you can resubscribe at any time from our website.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="unsubscribe-form">
                <div className="form-field">
                  <label htmlFor="email">Email Address</label>
                  <input 
                    type="email" 
                    id="email" 
                    className="form-input" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email address"
                    required
                  />
                </div>
                
                <div className="form-actions">
                  <button type="submit" className="btn btn-primary">Confirm Unsubscribe</button>
                </div>
              </form>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Unsubscribe;