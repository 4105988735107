import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/LegalPages.css';

const AccountDeletion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app">
      <Navigation />
      <main className="legal-page">
        <div className="container">
          <div className="legal-content">
            <h1>Deleting your Parcae account</h1>
            
            <section className="legal-section">
              <p>We're sorry to see you go! Follow these steps to delete your account.</p>
              <p><strong>Note:</strong> Make sure you are <strong>logged in</strong> to your account before starting.</p>
            </section>

            <section className="legal-section">
              <h2>Steps to Delete Your Account:</h2>
              
              <h3>Step 1: Cancel Subscription (if applicable)</h3>
              <ul>
                <li>If you don't have an active subscription, you can proceed directly to Step 2.</li>
                <li>If you do have a subscription, cancel it first.</li>
                <li>Wait up to 15 minutes after your active subscription expiration.</li>
              </ul>

              <h3>Step 2: Delete Your Account</h3>
              <ul>
                <li>Open the Parcae app and go to the Main Screen.</li>
                <li>Tap your Avatar Icon at the top.</li>
                <li>In the Navigation Drawer, select More ⋮ &gt; Delete Account.</li>
              </ul>
            </section>

            <section className="legal-section">
              <p>If you encounter any issues, feel free to contact our support team for help.</p>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccountDeletion;