/**
 * AuthContext
 * 
 * Provides authentication state and methods to the entire app.
 * This is a thin wrapper around the useAuth hook to make it available globally.
 */

import React, { createContext } from 'react';
import { useAuth as useAuthHook } from '../hooks/useAuth';

// Create context
const AuthContext = createContext();

// Provider component
export const AuthProvider = ({ children }) => {
  const auth = useAuthHook();
  
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use the auth context
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;