import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import authService from '../services/authService';
import '../styles/Verify2FAPage.css';

const Verify2FAPage = () => {
  const { verify2FA, loading } = useAuth();
  const location = useLocation();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);
  const [initializing, setInitializing] = useState(true);
  
  // Get userId on mount - only once
  useEffect(() => {
    // First check URL
    const params = new URLSearchParams(location.search);
    const urlUserId = params.get('userId');
    
    if (urlUserId) {
      setUserId(urlUserId);
      setInitializing(false);
      return;
    }
    
    // Then try from service
    const storedUserId = authService.getTempUserId();
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setError('No user ID found. Please restart the login process.');
    }
    
    setInitializing(false);
  }, [location.search]);

  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!userId) {
      setError('No user ID found. Please restart the login process.');
      return;
    }
    
    try {
      const result = await verify2FA(code, userId);
      
      if (!result.success) {
        setError(result.message || 'Verification failed');
      }
    } catch (err) {
      setError('Verification failed. Please try again.');
      console.error('Verification error:', err);
    }
  };

  if (initializing) {
    return (
      <div className="verify-2fa-container">
        <div className="verify-2fa-box">
          <h1>Two-Factor Authentication</h1>
          <div className="loading">Loading authentication details...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="verify-2fa-container">
      <div className="verify-2fa-box">
        <h1>Two-Factor Authentication</h1>
        {userId ? (
          <>
            <p>Please enter the 6-digit code from your authenticator app</p>
            
            <form onSubmit={handleVerify}>
              <div className="code-input-container">
                <input
                  type="text"
                  placeholder="6-digit code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={6}
                  pattern="[0-9]{6}"
                  required
                />
              </div>
              
              <button 
                type="submit" 
                className="submit-button"
                disabled={loading}
              >
                {loading ? 'Verifying...' : 'Verify'}
              </button>
            </form>
          </>
        ) : (
          <div className="error-container">
            <p>Unable to find your authentication session.</p>
            <p>Please restart the login process.</p>
          </div>
        )}
        
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default Verify2FAPage;