import React from 'react';
import { Box } from '@mui/material';
import { getCountryFlag } from '../../utils/countryUtils';

const CountryDisplay = ({ country }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <span role="img" aria-label={`Flag of ${country}`}>
        {getCountryFlag(country)}
      </span>
      {country}
    </Box>
  );
};

export default CountryDisplay;
