import React from 'react';
import { 
  Box, 
  TextField, 
  Grid, 
  Paper, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem
} from '@mui/material';
import { Language as LanguageIcon, Translate as TranslateIcon } from '@mui/icons-material';

/**
 * Component for template metadata form fields (name, description, subject, language)
 */
const TemplateMetadataForm = ({
  formData,
  handleChange,
  handleContentChange,
  errors,
  currentLanguage,
  handleLanguageChange,
  availableLanguages,
  languageNames,
  handleOpenTranslateDialog,
  translating
}) => {
  return (
    <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Template Name"
            fullWidth
            value={formData.name || ''}
            onChange={handleChange('name')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Subject Line"
            fullWidth
            value={formData.defaultContent?.subject || ''}
            onChange={handleContentChange('subject')}
            required
            error={Boolean(errors.subject)}
            helperText={errors.subject || 'Supports variables like {{user.name}}'}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={formData.description || ''}
            onChange={handleChange('description')}
            placeholder="What is this template used for?"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="language-selector-label">Language</InputLabel>
              <Select
                labelId="language-selector-label"
                value={currentLanguage}
                onChange={handleLanguageChange}
                label="Language"
                size="small"
                startAdornment={<LanguageIcon fontSize="small" sx={{ mr: 1, ml: -0.5 }} />}
              >
                {/* Always show English (US) as the default language first */}
                <MenuItem key="en" value="en">
                  {`${languageNames['en'] || 'English (US)'} (default)`}
                </MenuItem>
                
                {/* Display all available translations */}
                {availableLanguages
                  .filter(lang => lang !== 'en' && !!lang && typeof lang === 'string') // Only valid language codes
                  .sort() // Sort languages alphabetically
                  .map(lang => (
                    <MenuItem key={lang} value={lang}>
                      {languageNames[lang] || lang}
                    </MenuItem>
                  ))}
                
                {/* Show a disabled item if no translations exist yet */}
                {availableLanguages.length <= 1 && (
                  <MenuItem disabled>
                    <em>No translations available yet</em>
                  </MenuItem>
                )}
                
                {/* Add a divider and "Add Language" menu item */}
                <MenuItem 
                  divider 
                  sx={{ 
                    height: 1, 
                    m: 0, 
                    p: 0, 
                    opacity: 0.5, 
                    pointerEvents: 'none' 
                  }} 
                />
                <MenuItem 
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default select behavior
                    e.stopPropagation(); // Prevent the dropdown from closing
                    // Don't pass any value to the select onChange handler
                    setTimeout(() => handleOpenTranslateDialog(), 0);
                  }}
                  value={null} // Explicitly set value to null
                  disabled={!formData.id || translating}
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    color: 'primary.main'
                  }}
                >
                  <span>{translating ? 'Translating...' : 'Add language...'}</span>
                  <TranslateIcon fontSize="small" sx={{ ml: 1 }} />
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateMetadataForm;