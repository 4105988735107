/**
 * API service for image upload operations
 */

/**
 * Upload an image to the server
 * @param {File} file - The image file to upload
 * @returns {Promise<Object>} The uploaded image data (url, name, size, type)
 */
export const uploadImage = async (file) => {
  try {
    // Client-side validation
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('File size exceeds 5MB limit');
    }
    // Create FormData object
    const formData = new FormData();
    formData.append('image', file);
    
    // API endpoint for image upload
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    const uploadEndpoint = `${API_URL}/admin/email/templates/images/upload`;
    
    // Get auth token from storage (assuming this is how your auth is set up)
    const token = localStorage.getItem('admin_token') || '';
    
    // Send request to server
    const response = await fetch(uploadEndpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
        // Don't set Content-Type header - browser will set it with the correct boundary for multipart/form-data
      },
      body: formData
    });
    
    // Check if response is OK
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || `Server error: ${response.status}`);
    }
    
    // Parse JSON response
    const data = await response.json();
    
    // Handle relative URLs by prepending the API base URL
    let fullImageUrl = data.imageUrl;
    
    // Log the URL received from server for debugging
    console.log('Server returned image URL:', data.imageUrl);
    
    // Check if the URL is relative (doesn't start with http/https)
    if (data.imageUrl && !data.imageUrl.match(/^(https?:)?\/\//)) {
      try {
        // Create a proper URL
        let baseUrl;
        
        // For development environment with localhost
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          // Use window.location to ensure correct protocol and port
          baseUrl = `${window.location.protocol}//${window.location.host}`;
        } else {
          // For production, parse from API_URL
          const apiUrl = new URL(API_URL);
          baseUrl = apiUrl.origin;
        }
        
        // Clean the image path (remove any leading slashes)
        const cleanPath = data.imageUrl.replace(/^\/+/, '');
        
        // Combine with proper slash handling
        fullImageUrl = `${baseUrl}/${cleanPath}`;
        
        // Log the constructed URL for debugging
        console.log('Constructed full URL:', fullImageUrl);
      } catch (error) {
        // Fallback if URL parsing fails
        console.error('Error creating full image URL:', error);
        
        // Use window.location as the most reliable fallback
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const cleanPath = data.imageUrl.replace(/^\/+/, '');
        fullImageUrl = `${baseUrl}/${cleanPath}`;
      }
    }
    
    // Return data with the full URL
    return {
      url: fullImageUrl,
      name: data.filename,
      size: file.size,
      type: file.type
    };
  } catch (error) {
    console.error('Image upload error:', error);
    throw error;
  }
};