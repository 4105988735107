import { useState, useEffect } from 'react';

export default function useSorting(initialOrderBy = 'registrationDate', initialOrder = 'desc', onSortChange) {
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [order, setOrder] = useState(initialOrder);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    if (onSortChange) {
      onSortChange(orderBy, order);
    }
  }, [orderBy, order, onSortChange]);

  return {
    orderBy,
    order,
    handleRequestSort
  };
}
