/**
 * Authentication Service
 * 
 * Handles all authentication-related business logic independently from UI components.
 * Uses a singleton pattern to maintain authentication state across the application.
 */

import { jwtDecode } from 'jwt-decode';

// Base API URL - use 0.0.0.0 instead of 0.0.0.0
const API_BASE_URL = process.env.REACT_APP_API_URL || 
  (process.env.NODE_ENV === 'production' ? '' : 'http://0.0.0.0:8080');

class AuthService {
  constructor() {
    // Internal state
    this.isAuthenticated = false;
    this.user = null;
    this.isAdmin = false;
    this.token = null;
    
    // Event listeners for auth state changes
    this.listeners = [];
    
    // Initialize on creation
    this.initialize();
  }

  /**
   * Initialize authentication state from stored tokens
   */
  initialize() {
    try {
      const token = localStorage.getItem('admin_token');
      
      if (token) {
        const decoded = this.decodeToken(token);
        if (decoded && !this.isTokenExpired(decoded)) {
          this.setAuthState({
            isAuthenticated: true,
            user: decoded,
            isAdmin: decoded.isAdmin || decoded.role === 'ADMIN',
            token
          });
        } else {
          this.clearAuthState();
        }
      }
    } catch (error) {
      this.clearAuthState();
    }
  }

  /**
   * Subscribe to authentication state changes
   * @param {Function} listener - Callback function
   * @returns {Function} Unsubscribe function
   */
  subscribe(listener) {
    this.listeners.push(listener);
    
    // Return unsubscribe function
    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  /**
   * Notify all listeners of state changes
   */
  notifyListeners() {
    const authState = {
      isAuthenticated: this.isAuthenticated,
      user: this.user,
      isAdmin: this.isAdmin,
    };
    
    this.listeners.forEach(listener => listener(authState));
  }

  /**
   * Update authentication state
   * @param {Object} state - New auth state
   */
  setAuthState(state) {
    this.isAuthenticated = state.isAuthenticated;
    this.user = state.user;
    this.isAdmin = state.isAdmin;
    this.token = state.token;
    
    // Notify any subscribers
    this.notifyListeners();
  }

  /**
   * Clear authentication state
   */
  clearAuthState() {
    localStorage.removeItem('admin_token');
    localStorage.removeItem('temp_user_id');
    sessionStorage.removeItem('2fa_secret');
    
    this.setAuthState({
      isAuthenticated: false,
      user: null,
      isAdmin: false,
      token: null
    });
  }

  /**
   * Get current authentication state
   * @returns {Object} Authentication state
   */
  getAuthState() {
    return {
      isAuthenticated: this.isAuthenticated,
      user: this.user,
      isAdmin: this.isAdmin,
      loading: false
    };
  }

  /**
   * Check if token is expired
   * @param {Object} decodedToken - Decoded JWT token
   * @returns {boolean} True if token is expired
   */
  isTokenExpired(decodedToken) {
    if (!decodedToken.exp) return true;
    
    const currentTime = Date.now();
    const expirationTime = decodedToken.exp * 1000;
    
    return expirationTime < currentTime;
  }

  /**
   * Decode JWT token
   * @param {string} token - JWT token
   * @returns {Object} Decoded token payload
   */
  decodeToken(token) {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }

  /**
   * Make authenticated API request
   * @param {string} endpoint - API endpoint
   * @param {Object} options - Fetch options
   * @returns {Promise<Object>} API response
   */
  async apiRequest(endpoint, options = {}) {
    try {
      const url = `${API_BASE_URL}${endpoint}`;
      
      // Log URL in development mode for debugging
      if (process.env.NODE_ENV === 'development') {
        console.log(`Auth API Request: ${url}`);
        console.log('Options:', JSON.stringify(options));
      }
      
      const response = await fetch(url, options);
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API Error: ${response.status} - ${errorText}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Auth API Error:', error);
      
      // Add mock response for local testing when server is unavailable
      if (process.env.NODE_ENV === 'development' && error.message.includes('Failed to fetch')) {
        console.warn('Using mock auth response for development');
        
        // Mock successful response for testing
        if (endpoint === '/auth-google-web') {
          return {
            success: true,
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkRldiBVc2VyIiwiZW1haWwiOiJkZXZAZXhhbXBsZS5jb20iLCJpc0FkbWluIjp0cnVlLCJpYXQiOjE2MTYyMzkwMjIsImV4cCI6MTYxODgzMTAyMn0.3ENW9VvLJSMNbTS5IFXKo9r6rG0K3yvZeKGCbxTKzQU"
          };
        }
      }
      
      throw error;
    }
  }

  /**
   * Login with Google credentials
   * @param {string} credential - Google credential
   * @returns {Promise<Object>} Login result
   */
  async loginWithGoogle(credential) {
    try {
      // Clear any previous temp state
      localStorage.removeItem('temp_user_id');
      sessionStorage.removeItem('2fa_secret');
      
      
      // Get client ID for verification
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      
      // Add CORS mode to ensure proper cross-origin requests
      const data = await this.apiRequest('/auth-google-web', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          credential,
          clientId // Include the client ID in the request for verification
        }),
        mode: 'cors' // Explicitly set CORS mode
      });
      
      
      if (data.success) {
        if (data.needs2FA) {
          // Store user ID for 2FA verification
          if (data.userId) {
            localStorage.setItem('temp_user_id', data.userId);
            return {
              success: true,
              needs2FA: true,
              userId: data.userId,
              message: data.message || 'Please enter your 2FA code'
            };
          } else {
            throw new Error('Missing user ID in response');
          }
        }
        
        if (data.needsSetup2FA) {
          // Store user ID for 2FA setup
          if (data.userId) {
            localStorage.setItem('temp_user_id', data.userId);
            return {
              success: true,
              needsSetup2FA: true,
              userId: data.userId,
              message: data.message || 'Please set up 2FA'
            };
          } else {
            throw new Error('Missing user ID in response');
          }
        }
        
        if (data.token) {
          // Store token and update auth state
          localStorage.setItem('admin_token', data.token);
          const decoded = this.decodeToken(data.token);
          
          this.setAuthState({
            isAuthenticated: true,
            user: decoded,
            isAdmin: decoded.isAdmin || decoded.role === 'ADMIN',
            token: data.token
          });
          
          return {
            success: true,
            message: 'Login successful'
          };
        }
      }
      
      return {
        success: false,
        message: data.message || 'Login failed'
      };
    } catch (error) {
      
      // Check if this is a network error (which could indicate CORS issues)
      const errorMessage = error.name === 'TypeError' && error.message === 'Failed to fetch'
        ? 'Cannot connect to the authentication server. Please check your network connection or try again later.'
        : error.message || 'Login failed';
      
      return {
        success: false,
        message: errorMessage
      };
    }
  }

  /**
   * Verify 2FA code
   * @param {string} code - 2FA verification code
   * @param {string} providedUserId - User ID
   * @returns {Promise<Object>} Verification result
   */
  async verify2FA(code, providedUserId = null) {
    try {
      // Get user ID from param or localStorage
      let userId = providedUserId;
      if (!userId) {
        userId = localStorage.getItem('temp_user_id');
        if (!userId) {
          throw new Error('No user ID available for 2FA verification');
        }
      }
      
      const data = await this.apiRequest('/verify-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ code, userId })
      });
      
      if (data.success && data.token) {
        // Store token and update auth state
        localStorage.setItem('admin_token', data.token);
        localStorage.removeItem('temp_user_id');
        
        let userData = data.user;
        if (!userData) {
          userData = this.decodeToken(data.token);
        }
        
        this.setAuthState({
          isAuthenticated: true,
          user: userData,
          isAdmin: userData.isAdmin || userData.role === 'ADMIN',
          token: data.token
        });
        
        return {
          success: true,
          message: 'Authentication successful'
        };
      }
      
      return {
        success: false,
        message: data.message || 'Invalid 2FA code'
      };
    } catch (error) {
      return {
        success: false,
        message: error.message || 'Verification failed'
      };
    }
  }

  /**
   * Setup 2FA
   * @param {string} providedUserId - User ID
   * @returns {Promise<Object>} Setup result
   */
  async setup2FA(providedUserId = null) {
    try {
      // Get user ID from param or localStorage
      let userId = providedUserId;
      if (!userId) {
        userId = localStorage.getItem('temp_user_id');
        if (!userId) {
          throw new Error('No user ID available for 2FA setup');
        }
      }
      
      const data = await this.apiRequest('/setup-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ userId })
      });
      
      if (data.success) {
        // Store secret for confirmation
        sessionStorage.setItem('2fa_secret', data.secret);
        
        return {
          success: true,
          secret: data.secret,
          qrCodeUrl: data.qrCodeUrl
        };
      }
      
      return {
        success: false,
        message: data.message || 'Failed to set up 2FA'
      };
    } catch (error) {
      return {
        success: false,
        message: error.message || 'Failed to set up 2FA'
      };
    }
  }

  /**
   * Confirm 2FA setup
   * @param {string} code - 2FA verification code
   * @param {string} providedUserId - User ID
   * @param {string} providedSecret - 2FA secret
   * @returns {Promise<Object>} Confirmation result
   */
  async confirmSetup2FA(code, providedUserId = null, providedSecret = null) {
    try {
      // Get user ID from param or localStorage
      let userId = providedUserId;
      if (!userId) {
        userId = localStorage.getItem('temp_user_id');
        if (!userId) {
          throw new Error('No user ID available for 2FA confirmation');
        }
      }
      
      // Get secret from param or sessionStorage
      let secret = providedSecret;
      if (!secret) {
        secret = sessionStorage.getItem('2fa_secret');
        if (!secret) {
          throw new Error('No 2FA secret available');
        }
      }
      
      const data = await this.apiRequest('/confirm-2fa-setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ code, userId, secret })
      });
      
      if (data.success && data.token) {
        // Store token and update auth state
        localStorage.setItem('admin_token', data.token);
        localStorage.removeItem('temp_user_id');
        sessionStorage.removeItem('2fa_secret');
        
        let userData = data.user;
        if (!userData) {
          userData = this.decodeToken(data.token);
        }
        
        this.setAuthState({
          isAuthenticated: true,
          user: userData,
          isAdmin: userData.isAdmin || userData.role === 'ADMIN',
          token: data.token
        });
        
        return {
          success: true,
          message: '2FA set up successfully'
        };
      }
      
      return {
        success: false,
        message: data.message || 'Failed to confirm 2FA setup'
      };
    } catch (error) {
      return {
        success: false,
        message: error.message || 'Failed to confirm 2FA setup'
      };
    }
  }

  /**
   * Logout user
   */
  logout() {
    this.clearAuthState();
  }

  /**
   * Get stored user ID for 2FA
   * @returns {string|null} User ID
   */
  getTempUserId() {
    return localStorage.getItem('temp_user_id');
  }
}

// Export singleton instance
const authService = new AuthService();
export default authService;