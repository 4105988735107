export const logScale = (value) => {
  if (value <= 0) return 0;
  
  // Normalize to [0,1] range
  const x = value / 100000;
  
  // Apply the formula: 10 * (10,000 ^ x) - 1
  return 10 * Math.pow(10000, x) - 1;
};

export const logText = (value) => {
  return `${Math.round(logScale(value))}`;
};
