import React, { useState } from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Card, 
  CardContent,
  Grid,
  Divider,
  Chip,
  LinearProgress,
  Paper,
  Tab,
  Tabs
} from '@mui/material';
import { 
  Add as AddIcon,
  Send as SendIcon,
  PeopleOutline as AudienceIcon
} from '@mui/icons-material';
import { mockCampaigns } from '../admin/data/mockData';
import { colors } from '../admin/utils/constants';

const EmailCampaignsPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Calculate campaign metrics
  const getCampaignMetrics = (campaign) => {
    const openRate = (campaign.stats.opened / campaign.stats.sent * 100).toFixed(1);
    const clickRate = (campaign.stats.clicked / campaign.stats.opened * 100).toFixed(1);
    return { openRate, clickRate };
  };

  const CampaignCard = ({ campaign }) => {
    const { openRate, clickRate } = getCampaignMetrics(campaign);
    
    return (
      <Card sx={{ mb: 2, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {campaign.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Sent on {formatDate(campaign.sentDate)}
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                <Chip 
                  icon={<SendIcon fontSize="small" />} 
                  label={`Sent: ${campaign.stats.sent.toLocaleString()}`}
                  size="small"
                  variant="outlined"
                />
                <Chip 
                  icon={<AudienceIcon fontSize="small" />} 
                  label={`Audience: ${campaign.audience.language.join(', ')}`}
                  size="small"
                  variant="outlined"
                />
              </Box>
            </Box>
            
            <Box>
              <Button 
                variant="outlined" 
                size="small" 
                sx={{ mr: 1 }}
              >
                View Report
              </Button>
              <Button 
                variant="contained" 
                size="small"
                sx={{ 
                  background: colors.gradient,
                  boxShadow: 'none'
                }}
              >
                Duplicate
              </Button>
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {campaign.subject}
          </Typography>
          
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="body2" color="text.secondary">
                    Open Rate
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {openRate}%
                  </Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={parseFloat(openRate)} 
                  sx={{ 
                    height: 6, 
                    borderRadius: 3,
                    bgcolor: `${colors.primary}15`,
                    '& .MuiLinearProgress-bar': {
                      bgcolor: colors.primary
                    }
                  }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="body2" color="text.secondary">
                    Click Rate
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {clickRate}%
                  </Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={parseFloat(clickRate)} 
                  sx={{ 
                    height: 6, 
                    borderRadius: 3,
                    bgcolor: `${colors.secondary}15`,
                    '& .MuiLinearProgress-bar': {
                      bgcolor: colors.secondary
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Email Campaigns
        </Typography>
        
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
          }}
        >
          Create Campaign
        </Button>
      </Box>
      
      <Paper sx={{ borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', mb: 3 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            px: 2,
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 500,
              minWidth: 100
            },
            '& .Mui-selected': {
              color: colors.primary,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.primary,
            }
          }}
        >
          <Tab label="All Campaigns" />
          <Tab label="Drafts" />
          <Tab label="Scheduled" />
          <Tab label="Sent" />
        </Tabs>
        
        <Box sx={{ p: 3 }}>
          {tabValue === 0 && (
            <Box>
              {mockCampaigns.map((campaign) => (
                <CampaignCard key={campaign.id} campaign={campaign} />
              ))}
            </Box>
          )}
          
          {tabValue === 1 && (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No draft campaigns.
              </Typography>
            </Box>
          )}
          
          {tabValue === 2 && (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No scheduled campaigns.
              </Typography>
            </Box>
          )}
          
          {tabValue === 3 && (
            <Box>
              {mockCampaigns.map((campaign) => (
                <CampaignCard key={campaign.id} campaign={campaign} />
              ))}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default EmailCampaignsPage;