import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Tabs, 
  Tab, 
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  TextField,
  InputAdornment,
  Avatar,
  IconButton,
  Button,
  Chip
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Inbox as InboxIcon,
  CheckCircleOutline as ResolvedIcon,
  HourglassEmpty as PendingIcon,
  StarOutline,
  AttachFile as AttachmentIcon,
  Send as SendIcon
} from '@mui/icons-material';
import { mockEmails } from '../admin/data/mockData';
import { colors } from '../admin/utils/constants';

const SupportPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState(mockEmails[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [reply, setReply] = useState('');
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleEmailSelect = (email) => {
    setSelectedEmail(email);
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    // Check if it's today
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    }
    
    // Check if it's yesterday
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
    
    // Otherwise return the date
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const EmailList = () => (
    <List sx={{ p: 0 }}>
      {mockEmails.map((email) => (
        <React.Fragment key={email.id}>
          <ListItem 
            button
            selected={selectedEmail?.id === email.id}
            onClick={() => handleEmailSelect(email)}
            sx={{
              py: 2,
              px: 3,
              '&.Mui-selected': {
                backgroundColor: 'rgba(167, 119, 227, 0.08)',
                '&:hover': {
                  backgroundColor: 'rgba(167, 119, 227, 0.12)',
                },
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Avatar sx={{ width: 32, height: 32, bgcolor: colors.primary }}>
                {email.from.charAt(0).toUpperCase()}
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      fontWeight: email.read ? 400 : 500,
                      color: email.read ? 'text.secondary' : 'text.primary'
                    }}
                  >
                    {email.from.split('@')[0]}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatDate(email.date)}
                  </Typography>
                </Box>
              }
              secondary={
                <Box>
                  <Typography 
                    variant="body2" 
                    color="text.primary" 
                    sx={{ 
                      mb: 0.5,
                      fontWeight: email.read ? 400 : 500,
                      color: email.read ? 'text.secondary' : 'text.primary'
                    }}
                  >
                    {email.subject}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {email.body}
                  </Typography>
                </Box>
              }
              primaryTypographyProps={{ mb: 0.5 }}
            />
          </ListItem>
          <Divider component="li" />
        </React.Fragment>
      ))}
    </List>
  );

  const EmailDetail = () => (
    <Box sx={{ p: 3 }}>
      {selectedEmail ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ width: 40, height: 40, bgcolor: colors.primary, mr: 2 }}>
                {selectedEmail.from.charAt(0).toUpperCase()}
              </Avatar>
              <Box>
                <Typography variant="subtitle1" fontWeight={500}>
                  {selectedEmail.from.split('@')[0]}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {selectedEmail.from}
                </Typography>
              </Box>
            </Box>
            
            <Box>
              <Typography variant="body2" color="text.secondary">
                {new Date(selectedEmail.date).toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0.5 }}>
                <Chip 
                  label="Customer" 
                  size="small" 
                  sx={{ 
                    bgcolor: `${colors.info}15`,
                    color: colors.info,
                    fontWeight: 500,
                    mr: 1
                  }} 
                />
                <IconButton size="small">
                  <StarOutline fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
            {selectedEmail.subject}
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 4 }}>
            {selectedEmail.body}
          </Typography>
          
          <Divider sx={{ my: 3 }} />
          
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Reply
          </Typography>
          
          <TextField
            multiline
            rows={4}
            fullWidth
            placeholder="Type your reply here..."
            variant="outlined"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            sx={{ mb: 2 }}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              startIcon={<AttachmentIcon />}
            >
              Attach
            </Button>
            
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ 
                background: colors.gradient,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
            >
              Send
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="body1" color="text.secondary">
            Select an email to view
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Support
        </Typography>
        
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search support tickets..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small">
                  <FilterIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: 250 }}
        />
      </Box>
      
      <Paper sx={{ borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', height: 600 }}>
          {/* Left panel */}
          <Box sx={{ width: 360, borderRight: '1px solid rgba(0, 0, 0, 0.05)' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              sx={{ 
                borderBottom: 1, 
                borderColor: 'divider',
                minHeight: 48,
                '& .MuiTab-root': {
                  textTransform: 'none',
                  minHeight: 48,
                  fontWeight: 500
                },
                '& .Mui-selected': {
                  color: colors.primary,
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: colors.primary,
                }
              }}
            >
              <Tab icon={<InboxIcon />} label="Inbox" iconPosition="start" />
              <Tab icon={<ResolvedIcon />} label="Resolved" iconPosition="start" />
              <Tab icon={<PendingIcon />} label="Pending" iconPosition="start" />
            </Tabs>
            <Box sx={{ overflow: 'auto', height: 'calc(100% - 48px)' }}>
              {tabValue === 0 && <EmailList />}
              {tabValue === 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="body1" color="text.secondary">
                    No resolved tickets
                  </Typography>
                </Box>
              )}
              {tabValue === 2 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="body1" color="text.secondary">
                    No pending tickets
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          
          {/* Right panel - Email detail */}
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <EmailDetail />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SupportPage;