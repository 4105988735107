import { colors } from '../admin/utils/constants';

export const datePickerStyles = `
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
  }
  
  .react-datepicker {
    font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .react-datepicker__header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 10px;
  }
  
  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    padding-bottom: 10px;
  }
  
  .react-datepicker__day-name {
    color: #666666;
    font-weight: 500;
    font-size: 12px;
    margin: 4px;
    width: 28px;
  }
  
  .react-datepicker__day {
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin: 4px;
    border-radius: 50%;
    color: #333333;
  }
  
  .react-datepicker__day:hover {
    background-color: #f0f0f0;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background: ${colors.primary};
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background: ${colors.primary}80;
    color: white;
  }
  
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: ${colors.primary}80;
  }
  
  .react-datepicker__navigation {
    top: 12px;
  }
  
  .react-datepicker__navigation-icon::before {
    border-color: #666666;
  }
  
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    border-color: #666666;
  }
  
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover {
    background-color: ${colors.primary}20;
  }
  
  .react-datepicker__day--outside-month {
    color: #bbbbbb;
  }
  
  .react-datepicker__triangle {
    display: none;
  }
  
  /* Customize the close button */
  .react-datepicker__close-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    cursor: pointer;
  }
  
  .react-datepicker__close-icon::after {
    background-color: ${colors.primary};
    border-radius: 50%;
    color: white;
    content: '×';
    cursor: pointer;
    font-size: 14px;
    height: 16px;
    width: 16px;
    line-height: 14px;
    padding: 0;
    text-align: center;
    opacity: 0.8;
    transition: all 0.2s ease;
  }
  
  .react-datepicker__close-icon:hover::after {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
  }
`;
