import React from 'react';
import '../styles/Testimonials.css';

// Import avatar images
import avatar1 from '../assets/images/avatar-JuanMontejo.jpg';
import avatar2 from '../assets/images/avatar-MohitSingh.jpg';
import avatar3 from '../assets/images/avatar-nosequeponerxd.png';
import avatar4 from '../assets/images/avatar-PradeepKumar.jpg';
import avatar5 from '../assets/images/avatar-GagaSmith.jpg';
import avatar6 from '../assets/images/avatar-JaifinVj.png';
import avatar7 from '../assets/images/avatar-VadimShein.png';
import avatar8 from '../assets/images/avatar-RezaBukan.jpg';
import avatar9 from '../assets/images/avatar-Jaba.jpg';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      quote: "I recommend it because the mind maps are easy to understand and you can create your own",
      name: "Juan Montejo",
      title: "Google Play review",
      rating: 5,
      avatar: avatar1
    },
    {
      id: 2,
      quote: "It's the best app ever to generate mind maps. Cost is a bit too high but free features are awesome",
      name: "Mohit Singh",
      title: "Google Play review",
      rating: 5,
      avatar: avatar2
    },
    {
      id: 3,
      quote: "Very good app, I'm starting to use it and it has helped me do complex tasks step by step 10/10",
      name: "nosequeponerxd 2",
      title: "Google Play review",
      rating: 5,
      avatar: avatar3
    },
    {
      id: 4,
      quote: "Very good 👍 thanks 🙏",
      name: "PRADEEP KUMAR",
      title: "Google Play review",
      rating: 5,
      avatar: avatar4
    },
    {
      id: 5,
      quote: "It has good future. It's very affordable.",
      name: "Gaga Smith",
      title: "Google Play review",
      rating: 5,
      avatar: avatar5
    },
    {
      id: 6,
      quote: "Amazing piece of work",
      name: "Jaifin Vj",
      title: "Google Play review",
      rating: 5,
      avatar: avatar6
    },
    {
      id: 7,
      quote: "This is one of the most user-frendly phone apps I've seen. The app is minimalistic and intuitive, which made for a positive experience, and the new brainstorming feature is really cool",
      name: "Vadim Shein",
      title: "Director of Development",
      rating: 5,
      avatar: avatar7
    },
    {
      id: 8,
      quote: "Good application, really helped me in making a summary of learning material 1. My advice to developers is not to limit when zooming out because I want to screen capture it. 2. And also please add a feature to add files in the form of images if possible Edit: where is the pro version, i want to buy it",
      name: "Reza Bukan",
      title: "Google Play review",
      rating: 5,
      avatar: avatar8
    },
    {
      id: 9,
      quote: "I love it. Easy to use and practical. AI is great and cheaper than other platforms. Logo is the best than any other platforms.",
      name: "Jaba",
      title: "English Tutor",
      rating: 5,
      avatar: avatar9
    }
  ];

  // Render star rating
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="star">★</span>);
    }
    return <div className="rating">{stars}</div>;
  };

  return (
    <section id="testimonials" className="testimonials-section section">
      <div className="container">
        <div className="testimonials-badge animate-on-scroll">
          <span>Testimonials</span>
        </div>
        
        <div className="section-header">
          <h2 className="section-title animate-on-scroll">
            What <span className="italic">others</span> are saying.
          </h2>
          <p className="section-subtitle animate-on-scroll delay-100">
            Parcae has already been downloaded over 10,000 times on Android with a 4.2-star rating
          </p>
        </div>

        <div className="testimonials-grid">
          {testimonials.map((testimonial) => (
            <div 
              key={testimonial.id} 
              className={`testimonial-card animate-on-scroll ${
                testimonial.id <= 3 ? `delay-${testimonial.id * 100}` : 
                testimonial.id <= 6 ? `delay-${(testimonial.id - 3) * 100}` : 
                `delay-${(testimonial.id - 6) * 100}`
              }`}
            >
              {renderStars(testimonial.rating)}
              <p className="testimonial-quote">{testimonial.quote}</p>
              <div className="testimonial-author">
                <div className="author-avatar">
                  <img src={testimonial.avatar} alt={testimonial.name} />
                </div>
                <div className="author-info">
                  <h4 className="author-name">{testimonial.name}</h4>
                  <p className="author-title">{testimonial.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;