import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Tooltip
} from '@mui/material';
import {
  Upload as UploadIcon,
  Refresh as RefreshIcon,
  FileCopy as CopyIcon
} from '@mui/icons-material';

import { uploadImage } from './imageService';
import { formatFileSize, formatUrlForDisplay } from './imageUtils';

/**
 * Individual image uploader component
 */
const ImageItem = ({ image, index, onUpload, onReset }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(image.error);
  const [showPreview, setShowPreview] = useState(false);
  const fileInputRef = useRef(null);
  
  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      // Update local state
      setIsUploading(true);
      setError(null);
      
      // Start upload
      uploadImage(file)
        .then(result => {
          // Create object URL for immediate thumbnail preview
          const imageUrl = result.url;
          
          // Force image cache refresh by adding a timestamp query parameter
          const cachedUrl = `${imageUrl}?t=${new Date().getTime()}`;
          
          // Update UI immediately
          const updatedImage = {
            ...image,
            file,
            newSrc: imageUrl, // Store the clean URL for HTML
            previewSrc: cachedUrl, // Use cache-busted URL for preview
            uploaded: true,
            uploading: false,
            error: null
          };
          
          // Update parent component
          onUpload(index, updatedImage);
          setIsUploading(false);
        })
        .catch(err => {
          // Format error message
          let errorMsg = "Upload failed";
          
          if (err.message.includes("Network")) {
            errorMsg = "Network error. Please check your connection.";
          } else if (err.message.includes("5MB")) {
            errorMsg = "File size exceeds 5MB limit.";
          } else if (err.message.includes("401")) {
            errorMsg = "Authentication error. Please log in again.";
          } else if (err.message.includes("403")) {
            errorMsg = "You don't have permission to upload images.";
          } else if (err.message.includes("415")) {
            errorMsg = "Invalid file type. Please use jpg, png, or gif.";
          } else if (err.message.includes("500")) {
            errorMsg = "Server error. Please try again later.";
          } else if (err.message) {
            errorMsg = err.message;
          }
          
          // Handle upload error
          setError(errorMsg);
          onUpload(index, {
            ...image,
            file,
            uploading: false,
            error: errorMsg
          });
          setIsUploading(false);
        });
    }
  };
  
  const handleReset = () => {
    setError(null);
    onReset(index);
  };

  return (
    <Paper 
      elevation={0} 
      sx={{
        p: 2,
        mb: 2,
        border: '1px solid',
        borderColor: error ? 'error.light' : 
                    image.uploaded ? 'success.light' : 
                    'divider',
        borderRadius: 2,
        backgroundColor: error ? 'rgba(211, 47, 47, 0.04)' : 
                         image.uploaded ? 'rgba(46, 125, 50, 0.04)' : 
                         'background.paper'
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Preview thumbnail */}
        <Grid item xs={12} sm={3}>
          <Box 
            sx={{ 
              width: '100%',
              height: 120,
              backgroundImage: `url(${image.previewSrc || image.newSrc || image.originalSrc})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'rgba(0,0,0,0.04)',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.08)'
              }
            }}
            onClick={() => setShowPreview(true)}
          >
            {!image.originalSrc && !image.newSrc && (
              <Typography variant="body2" color="text.secondary">
                No preview
              </Typography>
            )}
          </Box>
        </Grid>
        
        {/* Image details */}
        <Grid item xs={12} sm={9}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              Image {index + 1} {image.alt && `(${image.alt})`}
            </Typography>
            
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Current Source"
                value={formatUrlForDisplay(image.originalSrc) || ''}
                InputProps={{
                  readOnly: true,
                  sx: { fontFamily: 'monospace', fontSize: '0.8rem' }
                }}
              />
            </Box>
            
            {image.newSrc && (
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="New Source"
                  value={formatUrlForDisplay(image.newSrc)}
                  InputProps={{
                    readOnly: true,
                    sx: { fontFamily: 'monospace', fontSize: '0.8rem' },
                    endAdornment: (
                      <Tooltip title="Copy URL">
                        <IconButton 
                          edge="end" 
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(image.newSrc);
                          }}
                        >
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              </Box>
            )}
            
            {image.file && (
              <Typography variant="caption" display="block" gutterBottom>
                {image.file.name} ({formatFileSize(image.file.size)})
              </Typography>
            )}
            
            {error && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {error}
              </Alert>
            )}
            
            {/* Upload actions */}
            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
              />
              
              <Button
                variant="outlined"
                size="small"
                startIcon={isUploading ? <CircularProgress size={16} /> : <UploadIcon />}
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading}
                sx={{ flexGrow: 1 }}
              >
                {image.uploaded ? 'Replace Image' : 'Upload Image'}
              </Button>
              
              {image.uploaded && (
                <Tooltip title="Reset to original">
                  <IconButton 
                    size="small"
                    onClick={handleReset}
                    color="default"
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      
      {/* Image preview dialog */}
      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth="md"
      >
        <DialogTitle>
          Image Preview
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            src={image.previewSrc || image.newSrc || image.originalSrc}
            alt={image.alt}
            sx={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain',
              display: 'block',
              margin: '0 auto'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreview(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ImageItem;