import React from 'react';
import logoImage from '../assets/images/logo_icon_512_white_transparent.png';

const Logo = () => {
  return (
    <img 
      src={logoImage} 
      alt="Parcae Logo" 
      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
    />
  );
};

export default Logo;