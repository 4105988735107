// Image utility functions for handling HTML image extraction and replacement

/**
 * Check if URL is a valid external URL (not a data URL or relative path)
 */
export const isValidExternalUrl = (url) => {
  if (!url) return false;
  
  // Check if it's a valid URL with http/https protocol
  try {
    const parsed = new URL(url);
    return parsed.protocol === 'http:' || parsed.protocol === 'https:';
  } catch (e) {
    return false;
  }
};

/**
 * Extract src of all img tags in HTML content
 */
export const extractImageSources = (htmlContent) => {
  if (!htmlContent) return [];
  
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const imgElements = doc.querySelectorAll('img');
  
  return Array.from(imgElements).map((img, index) => {
    const src = img.getAttribute('src');
    const isExternal = isValidExternalUrl(src);
    
    // Generate a unique identifier for this image instance
    // Include the index in DOM order to differentiate images with same URL
    const uniqueId = `img_${index}_${src}`;
    
    // Get some surrounding context to help identify this image's position
    const parent = img.parentElement;
    const parentNodeName = parent ? parent.nodeName.toLowerCase() : '';
    const parentClass = parent ? parent.getAttribute('class') || '' : '';
    const parentId = parent ? parent.getAttribute('id') || '' : '';
    
    return {
      id: uniqueId,
      originalSrc: src,
      width: img.getAttribute('width'),
      height: img.getAttribute('height'),
      alt: img.getAttribute('alt') || '',
      imgIndex: index, // Store the position in the document
      context: {
        parent: parentNodeName,
        parentClass,
        parentId
      },
      uploaded: isExternal, // Already consider external URLs as uploaded
      uploading: false,
      error: null,
      file: null,
      newSrc: isExternal ? src : null, // Use the URL as is if it's already external
      previewSrc: isExternal ? src : null // Initialize preview source
    };
  });
};

/**
 * Replace image sources in HTML content without recursively parsing HTML
 * which can cause <br> tag accumulation issues
 */
export const replaceImageSources = (htmlContent, images) => {
  if (!htmlContent) return htmlContent;
  
  // Use a more direct approach with targeted replacements
  // First, find all the image elements in the original HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const imgElements = doc.querySelectorAll('img');
  
  // Map images by index for easy lookup
  const imagesByIndex = images.reduce((acc, img) => {
    if (img.imgIndex !== undefined) {
      acc[img.imgIndex] = img;
    }
    return acc;
  }, {});
  
  // Apply changes to the DOM
  let modified = false;
  Array.from(imgElements).forEach((imgElement, index) => {
    const image = imagesByIndex[index];
    if (!image || !image.newSrc) return;
    
    const currentSrc = imgElement.getAttribute('src');
    
    // Only update if the src doesn't already match the new source
    if (currentSrc !== image.newSrc) {
      imgElement.setAttribute('src', image.newSrc);
      modified = true;
    }
  });
  
  // If no changes, return the original content
  if (!modified) return htmlContent;
  
  // Get the modified HTML directly from the body element
  // This avoids additional DOM parsing that can cause BR issues
  const bodyContent = doc.body.innerHTML;
  
  return bodyContent;
};

/**
 * Format file size for display
 */
export const formatFileSize = (bytes) => {
  if (bytes < 1024) return bytes + ' bytes';
  else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
  else return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
};

/**
 * Format URL for display (make it shorter if too long)
 */
export const formatUrlForDisplay = (url) => {
  if (!url) return '';
  
  try {
    // Make sure it's a valid URL format
    if (!url.match(/^(https?:)?\/\//) && !url.startsWith('data:')) {
      // If it's a relative path or non-URL string, return as is
      return url.length > 40 ? url.substring(0, 37) + '...' : url;
    }
    
    // For data URLs, just show a placeholder
    if (url.startsWith('data:')) {
      return '[Data URL]';
    }
    
    // Display a shortened version if the URL is too long
    if (url.length > 40) {
      const urlObj = new URL(url);
      const domain = urlObj.hostname;
      const path = urlObj.pathname;
      
      // Format as domain/...shortpath
      if (path.length > 20) {
        const shortPath = path.substring(0, 8) + '...' + path.substring(path.length - 8);
        return `${domain}/${shortPath}`;
      }
      
      return `${domain}${path}`;
    }
    
    return url;
  } catch (error) {
    // If there's any error parsing the URL, return it as is
    return url.length > 40 ? url.substring(0, 37) + '...' : url;
  }
};