import React, { useEffect, useState, useRef } from 'react';
import '../styles/AnimatedHeroText.css';

/**
 * Animated Hero Text Component
 * 
 * Creates a 3D falling text effect with blur transition
 */
const AnimatedHeroText = ({ text, startDelay = 200 }) => {
  // Split the input text into individual words
  const words = text.split(' ');
  
  // Track animation state for each word
  const [animatedWords, setAnimatedWords] = useState(words.map(() => false));
  
  // Use a ref to track if animation was initiated to prevent multiple animations
  const animationInitiated = useRef(false);
  
  useEffect(() => {
    // Only run the animation once
    if (animationInitiated.current) return;
    animationInitiated.current = true;
    
    // Animate each word with a staggered delay that overlaps the previous animation
    const staggerDelay = 80; // Fixed delay of 80ms between words for faster sequence
    
    words.forEach((_, index) => {
      setTimeout(() => {
        setAnimatedWords(prev => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }, startDelay + index * staggerDelay); // Overlapping staggered delay
    });
  }, [startDelay, words]); // Include dependencies
  
  return (
    <h1 className="animated-hero-title">
      {words.map((word, index) => (
        <span
          key={index}
          className={`animated-word ${animatedWords[index] ? 'animated-word-visible' : ''}`}
        >
          {word}
        </span>
      ))}
    </h1>
  );
};

export default AnimatedHeroText;