import { colors } from '../admin/utils/constants';

export const getStatusColor = (status) => {
  switch (status) {
    case 'subscribed':
      return {
        color: '#ffffff',  // White text
        bgColor: '#4caf50', // Green background
      };
    case 'expired':
      return {
        color: colors.error,
        bgColor: `${colors.error}15`,
      };
    case 'never-purchased':
      return {
        color: colors.info,
        bgColor: `${colors.info}15`,
      };
    default:
      return {
        color: colors.text.secondary,
        bgColor: '#f0f0f0',
      };
  }
};
