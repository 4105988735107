import { useState, useEffect, useCallback, useRef } from 'react';
import { logScale } from '../utils/numberUtils';

export default function useFilters(initialFilters, metadata, onFiltersChange) {
  // Filter state
  const [searchTerm, setSearchTerm] = useState(initialFilters?.searchTerm || '');
  const [startDateRegistered, setStartDateRegistered] = useState(initialFilters?.startDateRegistered || null);
  const [endDateRegistered, setEndDateRegistered] = useState(initialFilters?.endDateRegistered || null);
  const [startDateLastVisit, setStartDateLastVisit] = useState(initialFilters?.startDateLastVisit || null);
  const [endDateLastVisit, setEndDateLastVisit] = useState(initialFilters?.endDateLastVisit || null);
  const [spentRange, setSpentRange] = useState(initialFilters?.spentRange || [0, 100000]);
  const [selectedCountries, setSelectedCountries] = useState(initialFilters?.selectedCountries || []);
  const [selectedLanguages, setSelectedLanguages] = useState(initialFilters?.selectedLanguages || []);
  const [selectedSubscriptionStatuses, setSelectedSubscriptionStatuses] = useState(
    initialFilters?.selectedSubscriptionStatuses || []
  );
  
  // Track if filters have changed since last refresh
  const [filtersChanged, setFiltersChanged] = useState(false);
  
  // Store the initial filter state to detect changes
  const initialFilterState = useRef({
    searchTerm: searchTerm,
    startDateRegistered: startDateRegistered,
    endDateRegistered: endDateRegistered,
    startDateLastVisit: startDateLastVisit,
    endDateLastVisit: endDateLastVisit,
    spentRange: spentRange,
    selectedCountries: selectedCountries,
    selectedLanguages: selectedLanguages,
    selectedSubscriptionStatuses: selectedSubscriptionStatuses
  });

  // Build filters object for API - memoized with useCallback to avoid unnecessary re-renders
  const buildFiltersObject = useCallback(() => {
    const filters = {};
    
    // Registration date range
    if (startDateRegistered || endDateRegistered) {
      filters.registeredIn = {};
      if (startDateRegistered) {
        filters.registeredIn.since = startDateRegistered.toISOString().split('T')[0];
      }
      if (endDateRegistered) {
        filters.registeredIn.until = endDateRegistered.toISOString().split('T')[0];
      }
    }
    
    // Last AI attempt date range
    if (startDateLastVisit || endDateLastVisit) {
      filters.lastAiAttemptIn = {};
      if (startDateLastVisit) {
        filters.lastAiAttemptIn.since = startDateLastVisit.toISOString().split('T')[0];
      }
      if (endDateLastVisit) {
        filters.lastAiAttemptIn.until = endDateLastVisit.toISOString().split('T')[0];
      }
    }
    
    // Total spent range (only include if different from default)
    const minSpent = Math.round(logScale(spentRange[0]));
    const maxSpent = Math.round(logScale(spentRange[1]));
    if (minSpent > 0 || maxSpent < 100000) {
      filters.spentRange = {};
      if (minSpent > 0) filters.spentRange.min = minSpent;
      if (maxSpent < 100000) filters.spentRange.max = maxSpent;
    }
    
    // Countries: Only include if some but not all are selected
    if (metadata?.countries && selectedCountries.length > 0 && 
        selectedCountries.length < metadata.countries.length) {
      filters.countries = selectedCountries;
    }
    
    // Languages: Only include if some but not all are selected
    if (metadata?.languages && selectedLanguages.length > 0 && 
        selectedLanguages.length < metadata.languages.length) {
      filters.languages = selectedLanguages;
    }
    
    // Subscription statuses: Only include if some but not all are selected
    if (metadata?.subscriptionStatuses && selectedSubscriptionStatuses.length > 0 && 
        selectedSubscriptionStatuses.length < metadata.subscriptionStatuses.length) {
      filters.subscriptionStatuses = selectedSubscriptionStatuses;
    }
    
    // Search term
    if (searchTerm.trim()) {
      filters.searchTerm = searchTerm.trim();
    }
    
    return filters;
  }, [
    startDateRegistered,
    endDateRegistered,
    startDateLastVisit,
    endDateLastVisit,
    spentRange,
    metadata,
    selectedCountries,
    selectedLanguages,
    selectedSubscriptionStatuses,
    searchTerm
  ]);

  // Check if filters have changed
  useEffect(() => {
    const currentFilters = {
      searchTerm,
      startDateRegistered,
      endDateRegistered,
      startDateLastVisit,
      endDateLastVisit,
      spentRange: JSON.stringify(spentRange),
      selectedCountries: JSON.stringify(selectedCountries.sort()),
      selectedLanguages: JSON.stringify(selectedLanguages.sort()),
      selectedSubscriptionStatuses: JSON.stringify(selectedSubscriptionStatuses.sort())
    };
    
    const initialFilters = {
      searchTerm: initialFilterState.current.searchTerm,
      startDateRegistered: initialFilterState.current.startDateRegistered,
      endDateRegistered: initialFilterState.current.endDateRegistered,
      startDateLastVisit: initialFilterState.current.startDateLastVisit,
      endDateLastVisit: initialFilterState.current.endDateLastVisit,
      spentRange: JSON.stringify(initialFilterState.current.spentRange),
      selectedCountries: JSON.stringify([...(initialFilterState.current.selectedCountries || [])].sort()),
      selectedLanguages: JSON.stringify([...(initialFilterState.current.selectedLanguages || [])].sort()),
      selectedSubscriptionStatuses: JSON.stringify([...(initialFilterState.current.selectedSubscriptionStatuses || [])].sort())
    };
    
    // Check if anything has changed
    const hasChanged = Object.keys(currentFilters).some(
      key => JSON.stringify(currentFilters[key]) !== JSON.stringify(initialFilters[key])
    );
    
    setFiltersChanged(hasChanged);
  }, [
    searchTerm,
    startDateRegistered,
    endDateRegistered,
    startDateLastVisit,
    endDateLastVisit,
    spentRange,
    selectedCountries,
    selectedLanguages,
    selectedSubscriptionStatuses
  ]);

  // Update filters when any value changes
  useEffect(() => {
    // Only set up the effect if there's a callback to call
    if (!onFiltersChange) return;

    const handler = setTimeout(() => {
      onFiltersChange(buildFiltersObject());
    }, 500);
    
    return () => {
      clearTimeout(handler);
    };
  }, [
    // Since buildFiltersObject is memoized with all the filter dependencies,
    // we just need to include it and onFiltersChange here
    onFiltersChange,
    buildFiltersObject
  ]);
  
  // Reset the "changed" state after a refresh
  const resetFilterChangeState = () => {
    // Update the reference to current filter state
    initialFilterState.current = {
      searchTerm,
      startDateRegistered,
      endDateRegistered,
      startDateLastVisit,
      endDateLastVisit,
      spentRange,
      selectedCountries: [...selectedCountries],
      selectedLanguages: [...selectedLanguages],
      selectedSubscriptionStatuses: [...selectedSubscriptionStatuses]
    };
    
    // Reset changed state
    setFiltersChanged(false);
  };

  return {
    searchTerm,
    setSearchTerm,
    startDateRegistered,
    setStartDateRegistered,
    endDateRegistered,
    setEndDateRegistered,
    startDateLastVisit,
    setStartDateLastVisit,
    endDateLastVisit,
    setEndDateLastVisit,
    spentRange,
    setSpentRange,
    selectedCountries,
    setSelectedCountries,
    selectedLanguages,
    setSelectedLanguages,
    selectedSubscriptionStatuses,
    setSelectedSubscriptionStatuses,
    buildFiltersObject,
    filtersChanged,
    resetFilterChangeState
  };
}
