import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Alert
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const DeleteConfirmation = ({ 
  open, 
  onClose, 
  onConfirm, 
  templateName, 
  isUsedInCampaigns = false,
  campaignCount = 0
}) => {
  const [confirmText, setConfirmText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const resetState = () => {
    setConfirmText('');
    setLoading(false);
    setError('');
  };
  
  const handleClose = () => {
    resetState();
    onClose();
  };
  
  const handleConfirmChange = (event) => {
    setConfirmText(event.target.value);
    if (error) setError('');
  };
  
  const handleConfirm = async () => {
    if (confirmText !== 'DELETE') {
      setError('Please type DELETE to confirm');
      return;
    }
    
    try {
      setLoading(true);
      
      if (onConfirm) {
        const result = await onConfirm();
        
        if (result && result.error) {
          throw new Error(result.error);
        }
      }
      
      handleClose();
    } catch (error) {
      console.error('Error deleting template:', error);
      setError(error.message || 'An error occurred while deleting the template');
      setLoading(false); // Make sure to reset loading state on error
    }
  };
  
  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          color: 'error.main'
        }}
      >
        <DeleteIcon color="error" /> 
        Delete Template
      </DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          You are about to delete the template: <strong>{templateName}</strong>
        </DialogContentText>
        
        {isUsedInCampaigns && (
          <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body2">
              This template is used in {campaignCount} active {campaignCount === 1 ? 'campaign' : 'campaigns'}.
              Deleting it will affect these campaigns.
            </Typography>
          </Alert>
        )}
        
        <DialogContentText sx={{ mt: 2 }}>
          This action cannot be undone. All data associated with this template will be permanently deleted.
        </DialogContentText>
        
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
            Type DELETE to confirm:
          </Typography>
          <TextField
            fullWidth
            value={confirmText}
            onChange={handleConfirmChange}
            placeholder="DELETE"
            error={!!error}
            helperText={error}
            autoFocus
            inputProps={{
              sx: { textTransform: 'uppercase' }
            }}
          />
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button 
          onClick={handleClose} 
          disabled={loading}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="error"
          onClick={handleConfirm}
          disabled={loading || confirmText !== 'DELETE'}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DeleteIcon />}
        >
          {loading ? 'Deleting...' : 'Delete Template'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;