import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Visibility as PreviewIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { colors } from '../admin/utils/constants';
import { formatDistanceToNow } from 'date-fns';
import apiService from '../services/api';
import TemplateEditor from '../components/email/TemplateEditor';
import TemplatePreview from '../components/email/TemplatePreview';
import DeleteConfirmation from '../components/email/DeleteConfirmation';

const EmailTemplatesPage = () => {
  // State
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  
  // Add a state to trigger fetch refresh
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  
  // Force refresh of templates
  const refreshTemplates = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  // Load templates
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await apiService.admin.getEmailTemplates({
          page: page + 1,
          limit: rowsPerPage,
          search: searchQuery
        });
        
        setTemplates(response.templates);
        setTotalCount(response.total);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchTemplates();
  }, [page, rowsPerPage, searchQuery, refreshTrigger]);
  
  // Handlers
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page when searching
  };
  
  const handleClearSearch = () => {
    setSearchQuery('');
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleOpenCreateTemplate = () => {
    setCurrentTemplate(null); // Reset to create a new template
    setEditorOpen(true);
  };
  
  const handleOpenEditTemplate = (template) => {
    setCurrentTemplate(template);
    setEditorOpen(true);
  };
  
  const handleOpenPreviewTemplate = (template) => {
    setCurrentTemplate(template);
    setPreviewOpen(true);
  };
  
  const handleCloseEditor = () => {
    setEditorOpen(false);
    setCurrentTemplate(null);
  };
  
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setCurrentTemplate(null);
  };
  
  const handleSaveTemplate = async (templateData) => {
    try {
      let response;
      
      if (currentTemplate) {
        // Update existing template
        response = await apiService.admin.updateEmailTemplate(
          currentTemplate.id,
          templateData
        );
        
        // Update the template in the list
        const updatedTemplates = templates.map(t => 
          t.id === currentTemplate.id ? response : t
        );
        
        setTemplates(updatedTemplates);
      } else {
        // Create new template
        response = await apiService.admin.createEmailTemplate(templateData);
        // Prevent eslint warning about unused variable
        
        // Add the new template to the list
        setTemplates([response, ...templates]);
        setTotalCount(prev => prev + 1);
      }
      
      // Force refresh of templates to ensure we have the latest data
      refreshTemplates();
      
      // Close the editor
      handleCloseEditor();
      return { success: true };
    } catch (error) {
      console.error('Error saving template:', error);
      // Return error information to the caller
      return { 
        error: error.message || 'An error occurred while saving the template', 
        success: false 
      };
    }
  };
  
  const openDeleteDialog = (template) => {
    setTemplateToDelete(template);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteTemplate = async () => {
    if (!templateToDelete) return;
    
    try {
      // Call the API to delete the template
      await apiService.admin.deleteEmailTemplate(templateToDelete.id);
      
      // Force update of the templates list to remove the deleted template
      const updatedTemplates = templates.filter(t => t.id !== templateToDelete.id);
      setTemplates(updatedTemplates);
      setTotalCount(prev => prev - 1);
      
      // Force refresh of the templates data
      refreshTemplates();
      
      // Return success to the caller
      return { success: true };
    } catch (error) {
      console.error('Error deleting template:', error);
      return { error: error.message, success: false };
    } finally {
      setDeleteDialogOpen(false);
      setTemplateToDelete(null);
    }
  };

  // Format date as "2 days ago" or similar
  const formatDate = (dateString) => {
    try {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    } catch (error) {
      return dateString;
    }
  };
  
  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Email Templates
        </Typography>
        
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleOpenCreateTemplate}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
          }}
        >
          Create Template
        </Button>
      </Box>
      
      {/* Search and filters */}
      <Box sx={{ mb: 3 }}>
        <TextField
          placeholder="Search templates..."
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch} edge="end" size="small">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: 2 }
          }}
        />
      </Box>
      
      {/* Templates table */}
      <Paper sx={{ width: '100%', borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center" sx={{ py: 4 }}>
                    <CircularProgress size={24} sx={{ mr: 2 }} />
                    Loading templates...
                  </TableCell>
                </TableRow>
              ) : templates.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center" sx={{ py: 4 }}>
                    No templates found
                  </TableCell>
                </TableRow>
              ) : (
                templates.map((template) => (
                  <TableRow key={template.id} hover>
                    <TableCell>
                      <Box sx={{ fontWeight: 500, color: colors.primary }}>
                        {template.name}
                      </Box>
                    </TableCell>
                    <TableCell>{template.description}</TableCell>
                    <TableCell>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          maxWidth: 250,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {template.defaultContent?.subject || template.subject || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>{formatDate(template.createdAt)}</TableCell>
                    <TableCell>{formatDate(template.updatedAt)}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit template">
                        <IconButton 
                          size="small"
                          sx={{ color: colors.text.secondary, mr: 1 }}
                          onClick={() => handleOpenEditTemplate(template)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Preview template">
                        <IconButton 
                          size="small" 
                          sx={{ color: colors.text.secondary, mr: 1 }}
                          onClick={() => handleOpenPreviewTemplate(template)}
                        >
                          <PreviewIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete template">
                        <IconButton 
                          size="small" 
                          sx={{ color: colors.error }}
                          onClick={() => openDeleteDialog(template)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      
      {/* Template Editor Dialog */}
      {editorOpen && (
        <Dialog
          open={editorOpen}
          onClose={handleCloseEditor}
          maxWidth="xl"
          fullWidth
        >
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                {currentTemplate ? 'Edit Template' : 'Create Template'}
              </Typography>
              <IconButton onClick={handleCloseEditor}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TemplateEditor 
              template={currentTemplate} 
              onSave={handleSaveTemplate} 
            />
          </Box>
        </Dialog>
      )}
      
      {/* Template Preview Dialog */}
      {previewOpen && currentTemplate && (
        <Dialog
          open={previewOpen}
          onClose={handleClosePreview}
          maxWidth="xl"
          fullWidth
        >
          <Box sx={{ p: 3 }}>
            <TemplatePreview 
              template={currentTemplate} 
              onClose={handleClosePreview} 
            />
          </Box>
        </Dialog>
      )}
      
      {/* Delete Confirmation Dialog */}
      <DeleteConfirmation
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteTemplate}
        templateName={templateToDelete?.name}
        // In a real implementation, these would come from the API
        isUsedInCampaigns={false}
        campaignCount={0}
      />
    </Box>
  );
};

export default EmailTemplatesPage;