export const DEFAULT_COUNTRIES = ["US", "UK", "DE", "FR", "ES"];
export const DEFAULT_LANGUAGES = ["en", "es", "fr", "de", "it"];
export const DEFAULT_SUBSCRIPTION_STATUSES = ["subscribed", "expired", "never-purchased"];

export const SPENT_SLIDER_MARKS = [
  { value: 0, label: '0' },
  { value: 50000, label: '1,000' },
  { value: 100000, label: '100,000' },
];

export const SORTABLE_COLUMNS = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'email', label: 'Email', sortable: true },
  { id: 'registrationDate', label: 'Registered', sortable: true },
  { id: 'lastAiAttemptDate', label: 'Last AI Attempt', sortable: true },
  { id: 'totalSpent', label: 'Tokens Spent', sortable: true },
  { id: 'subscriptionStatus', label: 'Subscription Status', sortable: true },
  { id: 'country', label: 'Country', sortable: false },
  { id: 'language', label: 'Language', sortable: false },
  { id: 'actions', label: 'Actions', sortable: false, align: 'right' }
];
