import React from 'react';
import { Box, Typography, Stack, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { colors } from '../../admin/utils/constants';

const DateRangeFilter = ({ 
  startDate, 
  endDate, 
  setStartDate, 
  setEndDate, 
  label 
}) => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>{label}</Typography>
      <Stack direction="row" spacing={2}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="caption" gutterBottom>From</Typography>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            dateFormat="dd/MM/yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
            className="react-datepicker-custom"
            customInput={
              <TextField 
                fullWidth 
                size="small"
                sx={{ width: '100%' }}
                InputProps={{
                  sx: { 
                    borderRadius: 1,
                    '&:hover': { borderColor: colors.primary }
                  }
                }}
              />
            }
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant="caption" gutterBottom>To</Typography>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            dateFormat="dd/MM/yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
            className="react-datepicker-custom"
            customInput={
              <TextField 
                fullWidth 
                size="small" 
                sx={{ width: '100%' }}
                InputProps={{
                  sx: { 
                    borderRadius: 1,
                    '&:hover': { borderColor: colors.primary }
                  }
                }}
              />
            }
          />
        </Box>
      </Stack>
    </>
  );
};

export default DateRangeFilter;
