import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Tooltip,
  CircularProgress,
  Snackbar,
  Alert,
  Chip
} from '@mui/material';
import {
  PhoneAndroid as MobileIcon,
  Laptop as DesktopIcon,
  TabletAndroid as TabletIcon,
  Send as SendIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { colors } from '../../admin/utils/constants'; // Used for styling
import apiService from '../../services/api';

// Variable list component
const VariableList = ({ variables }) => {
  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Template Variables
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        The following variables are used in this template:
      </Typography>
      
      <Paper elevation={0} sx={{ p: 2, backgroundColor: '#f5f5f5', borderRadius: 1 }}>
        {variables.length > 0 ? (
          <Grid container spacing={1}>
            {variables.map((variable) => (
              <Grid item xs={12} sm={6} key={variable}>
                <Chip 
                  label={`{{${variable}}}`}
                  variant="outlined"
                  sx={{ 
                    fontFamily: 'monospace',
                    mb: 1,
                    borderColor: colors.primary,
                    color: colors.primary
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No variables detected in this template.
          </Typography>
        )}
      </Paper>
      
      <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
        Variables enclosed in double curly braces will be replaced with actual data when sending emails.
      </Typography>
    </Box>
  );
};

// Test Email Dialog
const TestEmailDialog = ({ open, onClose, onSend }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError('');
  };
  
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  
  const handleSend = () => {
    if (!email) {
      setError('Email address is required');
      return;
    }
    
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    onSend(email);
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Test Email</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Send a test email to verify how your template will look in an email client.
          <Box component="span" sx={{ fontWeight: 'bold', color: 'warning.main', display: 'block', mt: 1 }}>
            This will send an actual email to the address you provide.
          </Box>
        </Typography>
        <TextField
          label="Recipient Email Address"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={!!error}
          helperText={error}
          autoFocus
          margin="dense"
          placeholder="test@example.com"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSend} 
          variant="contained"
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none'
          }}
        >
          Send Test
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TemplatePreview = ({ template = {}, onClose }) => {
  // State
  const [viewMode, setViewMode] = useState('desktop');
  const [activeTab, setActiveTab] = useState(0);
  const [testEmailDialogOpen, setTestEmailDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // Mock test data (will be used in the future)
  // eslint-disable-next-line no-unused-vars
  const [testData] = useState({
    'user.name': 'John Doe',
    'user.email': 'john.doe@example.com',
    'user.formatted_created_date': 'January 15, 2023',
    'user.formatted_subscription_expiry': 'January 15, 2024',
    'user.balance': '$50.00',
    'user.totalSpent': '$350.00',
    'campaign.name': 'Monthly Newsletter',
    'campaign.subject': 'Your Monthly Update',
    'current_date': 'March 31, 2023',
    'unsubscribe_link': '#unsubscribe',
    'preferences_link': '#preferences'
  });
  
  // Extract variables from template safely
  const variables = template?.variables || [];
  
  // In the updated version, we don't replace variables but highlight them
  const highlightVariables = (content) => {
    if (!content) return '';
    
    // We'll just return the content as is, preserving the {{variable}} format
    // In a real implementation, you might want to add CSS to highlight these
    return content;
  };
  
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Handle view mode change
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };
  
  // We no longer need to update test data since we're keeping variables as is
  // But we'll keep the testData for future functionality
  
  // Handle send test email
  const handleSendTestEmail = async (email) => {
    try {
      setLoading(true);
      
      // Call API with the template and recipient email
      const testData = { ...template, receiverEmail: email };
      const response = await apiService.admin.testEmailTemplate(testData, email);
      
      setSnackbar({
        open: true,
        message: response.message || `Test email sent to ${email}`,
        severity: response.success ? 'success' : 'error'
      });
    } catch (error) {
      console.error('Error sending test email:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to send test email',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };
  
  // Get preview content based on tab
  const getPreviewContent = () => {
    if (activeTab === 0) {
      return highlightVariables(template?.htmlContent || '');
    } else {
      return highlightVariables(template?.textContent || '');
    }
  };
  
  // Get preview container styles based on view mode
  const getPreviewContainerStyles = () => {
    switch (viewMode) {
      case 'mobile':
        return {
          maxWidth: 375,
          height: 667,
          margin: '0 auto',
          border: '12px solid #222',
          borderRadius: 16,
          overflow: 'auto'
        };
      case 'tablet':
        return {
          maxWidth: 768,
          height: 800,
          margin: '0 auto',
          border: '12px solid #222',
          borderRadius: 16,
          overflow: 'auto'
        };
      default:
        return {
          width: '100%',
          height: 600,
          overflow: 'auto'
        };
    }
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Template Preview
        </Typography>
        
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ 
            borderRadius: 2, 
            boxShadow: '0 2px 10px rgba(0,0,0,0.05)', 
            mb: 3,
            overflow: 'hidden'
          }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Tabs 
                  value={activeTab} 
                  onChange={handleTabChange}
                  sx={{ 
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 500,
                      minWidth: 100
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: colors.primary,
                    }
                  }}
                >
                  <Tab label="HTML" />
                  <Tab label="Plain Text" />
                </Tabs>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Mobile view">
                    <IconButton 
                      onClick={() => handleViewModeChange('mobile')} 
                      color={viewMode === 'mobile' ? 'primary' : 'default'}
                      size="small"
                    >
                      <MobileIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Tablet view">
                    <IconButton 
                      onClick={() => handleViewModeChange('tablet')} 
                      color={viewMode === 'tablet' ? 'primary' : 'default'}
                      size="small"
                    >
                      <TabletIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Desktop view">
                    <IconButton 
                      onClick={() => handleViewModeChange('desktop')} 
                      color={viewMode === 'desktop' ? 'primary' : 'default'}
                      size="small"
                    >
                      <DesktopIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            
            <Box sx={{ p: 0 }}>
              <Box sx={{ 
                p: 2, 
                border: '1px solid rgba(0, 0, 0, 0.1)', 
                borderRadius: 1, 
                m: 2, 
                backgroundColor: 'rgba(0, 0, 0, 0.02)' 
              }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Subject: {template?.subject || ''}
                </Typography>
              </Box>
              
              <Box sx={{ p: 2 }}>
                <Box sx={getPreviewContainerStyles()}>
                  {activeTab === 0 ? (
                    <div dangerouslySetInnerHTML={{ __html: getPreviewContent() }} />
                  ) : (
                    <pre style={{ 
                      whiteSpace: 'pre-wrap', 
                      fontFamily: 'monospace', 
                      fontSize: '14px', 
                      padding: '16px' 
                    }}>
                      {getPreviewContent()}
                    </pre>
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>
          
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
              onClick={() => setTestEmailDialogOpen(true)}
              disabled={loading}
              sx={{ 
                background: colors.gradient,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
            >
              {loading ? 'Sending...' : 'Send Test Email'}
            </Button>
          </Box>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
            <VariableList 
              variables={variables}
            />
          </Paper>
        </Grid>
      </Grid>
      
      <TestEmailDialog 
        open={testEmailDialogOpen}
        onClose={() => setTestEmailDialogOpen(false)}
        onSend={handleSendTestEmail}
      />
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TemplatePreview;