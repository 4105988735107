import React from 'react';
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

const NotificationsMenu = ({ anchorEl, open, handleOpen, handleClose }) => {
  const notifications = [
    {
      id: 1,
      title: 'New user registered',
      message: 'John Smith just signed up as a premium user',
    },
    {
      id: 2,
      title: 'Support request',
      message: 'New high priority support request received',
    },
    {
      id: 3,
      title: 'Server update',
      message: 'System update completed successfully',
    },
  ];

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          onClick={handleOpen}
          size="large"
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            mt: 1.5,
            width: 320,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1.5,
              borderBottom: '1px solid #f0f0f0',
            },
          },
        }}
      >
        {notifications.map((notification) => (
          <MenuItem key={notification.id} onClick={handleClose}>
            <Box>
              <Typography variant="subtitle2">{notification.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {notification.message}
              </Typography>
            </Box>
          </MenuItem>
        ))}
        {notifications.length === 0 && (
          <MenuItem onClick={handleClose}>
            <Typography variant="body2">No new notifications</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NotificationsMenu;