import React from 'react';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Email as EmailIcon,
  QuestionAnswer as SupportIcon,
  DescriptionOutlined as TemplatesIcon,
} from '@mui/icons-material';

// Constants for the admin panel
export const drawerWidth = 240;

export const menuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/admin/dashboard' },
  { text: 'Users', icon: <PeopleIcon />, path: '/admin/users' },
  { text: 'Email Campaigns', icon: <EmailIcon />, path: '/admin/email-campaigns' },
  { text: 'Email Templates', icon: <TemplatesIcon />, path: '/admin/email-templates' },
  { text: 'Support', icon: <SupportIcon />, path: '/admin/support' },
];

// Theme colors for the admin panel
export const colors = {
  primary: '#a777e3', // Purple
  secondary: '#6e8efb', // Blue
  gradient: 'linear-gradient(45deg, #6e8efb, #a777e3)',
  success: '#4caf50', // Green
  warning: '#ff9800', // Orange
  error: '#f44336', // Red
  info: '#2196f3', // Light Blue
  background: '#f8f9fa', // Light Gray
  text: {
    primary: '#333333',
    secondary: '#666666',
    disabled: '#999999',
  },
};