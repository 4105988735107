import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Box, 
  Typography 
} from '@mui/material';
import { colors } from '../../../admin/utils/constants';

/**
 * Dialog for sending test emails
 */
const TestEmailDialog = ({ open, onClose, onSend }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError('');
  };
  
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  
  const handleSend = () => {
    if (!email) {
      setError('Email address is required');
      return;
    }
    
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    onSend(email);
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Test Email</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Send a test email to verify how your template will look in an email client.
          <Box component="span" sx={{ fontWeight: 'bold', color: 'warning.main', display: 'block', mt: 1 }}>
            This will send an actual email to the address you provide.
          </Box>
        </Typography>
        <TextField
          label="Recipient Email Address"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={!!error}
          helperText={error}
          autoFocus
          margin="dense"
          placeholder="test@example.com"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSend} 
          variant="contained"
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none'
          }}
        >
          Send Test
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestEmailDialog;