import React, { useEffect, useRef } from 'react';
import '../styles/HeroSection.css';
import AnimatedHeroText from './AnimatedHeroText';

const HeroSection = () => {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!videoRef.current || !videoContainerRef.current) return;
      
      // Get the position and dimensions of the video container
      const container = videoContainerRef.current.getBoundingClientRect();
      
      // Calculate the position relative to viewport
      const containerTop = container.top;
      const windowHeight = window.innerHeight;
      
      // Create a very obvious scroll effect that works over the entire viewport
      // Start at max scale when the video is at the top, and scale down as it moves down
      // This needs to be much more dramatic to be noticed
      
      // Calculate how far we've scrolled as a percentage (0 at top of screen, 1 when element is offscreen)
      // Use a larger divisor (windowHeight * 2) to make the effect happen more gradually
      const scrollProgress = Math.min(Math.max(0, (Math.abs(containerTop) / (windowHeight * 0.8))), 1);
      
      // Make the scale effect much more dramatic - from 1.3 to 0.9
      // When scrollProgress is 0, scale is 1.3 (30% larger)
      // When scrollProgress is 1, scale is 0.9 (10% smaller)
      const scale = 1.3 - (scrollProgress * 0.4);
      
      // Apply the scale transform to the container instead of just the video
      videoContainerRef.current.style.transform = `scale(${scale})`;
      
      // Create a more controlled parallax effect that won't cause clipping
      // Move up initially, then stay in place
      const parallaxOffset = scrollProgress < 0.5 
        ? scrollProgress * -40 
        : -20; // Cap at -20px after 50% scroll
      
      videoContainerRef.current.style.translate = `0 ${parallaxOffset}px`;
      
      // Show scale in data attribute for debugging
      videoContainerRef.current.dataset.scale = scale.toFixed(3);
      
      // Store the scale in a data attribute for reference
      const roundedScale = Math.round(scale * 100);
      if (roundedScale % 5 === 0 && roundedScale !== videoContainerRef.current._lastLoggedScale) {
        videoContainerRef.current._lastLoggedScale = roundedScale;
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Call handleScroll once to set initial state
    handleScroll();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="hero" className="hero-section">
      <div className="container hero-container">
        <div className="hero-content">
          <div className="hero-badge animate-on-scroll">
            <span>AI Mind Map Generator</span>
          </div>
          
          <AnimatedHeroText text="Reach any goals with AI mind maps" />
          
          <p className="hero-description animate-on-scroll delay-200">
            The revolutionary AI-powered mobile mind mapping app that transforms chaos into clarity, 
            boosting your productivity and workflow.
          </p>
          
          <div className="hero-cta animate-on-scroll delay-300">
            <a 
              href="https://play.google.com/store/apps/details?id=com.parcae&hl=en_US" 
              className="btn btn-primary hero-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download App
            </a>
            <a href="#how-it-works" className="btn btn-outline hero-btn">
              Learn More
            </a>
          </div>
        </div>
        
        <div className="video-wrapper">
          <div className="hero-video-container" ref={videoContainerRef}>
            <video 
              ref={videoRef}
              src="/videos/hero-video.mp4" 
              alt="Parcae AI Mind Mapping App" 
              className="hero-video"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>
      </div>
      
      <div className="hero-background"></div>
    </section>
  );
};

export default HeroSection;