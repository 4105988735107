import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { Save as SaveIcon, Send as SendIcon } from '@mui/icons-material';
import { colors } from '../../../admin/utils/constants';

/**
 * Component for template action buttons (send test, save draft, publish)
 */
const TemplateActions = ({ 
  onSendTest, 
  onSave, 
  saving, 
  validating, 
  imageValidation 
}) => {
  const isDisabled = saving || 
                     validating || 
                     imageValidation.pendingUploads || 
                     imageValidation.hasErrors;
  
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
      <Box>
        <Button
          variant="outlined"
          startIcon={<SendIcon />}
          onClick={onSendTest}
        >
          Send Test Email
        </Button>
      </Box>
      
      <Box>
        <Button
          variant="outlined"
          onClick={() => onSave(true)}
          disabled={isDisabled}
          sx={{ mr: 2 }}
        >
          Save as Draft
        </Button>
        <Button
          variant="contained"
          startIcon={saving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
          onClick={() => onSave(false)}
          disabled={isDisabled}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
          }}
        >
          {saving ? 'Saving...' : 'Save & Publish'}
        </Button>
      </Box>
    </Box>
  );
};

export default TemplateActions;