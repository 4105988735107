import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import '../styles/Footer.css';
import footerBgImage from '../assets/images/spooky.jpg';

const Footer = () => {
  return (
    <footer className="footer">
      <div 
        className="footer-bg"
        style={{ backgroundImage: `url(${footerBgImage})` }}
      ></div>
      <div className="container footer-container">

        <div className="footer-middle">
          <div className="footer-links">
            <div className="footer-links-group">
              <h4>Sections</h4>
              <ul>
                <li><HashLink to="/#how-it-works">How It Works</HashLink></li>
                <li><HashLink to="/#features">Features</HashLink></li>
                <li><HashLink to="/#testimonials">Testimonials</HashLink></li>
              </ul>
            </div>
            
            <div className="footer-links-group">
              <h4>Information</h4>
              <ul>
                <li><HashLink to="/#faq">FAQ</HashLink></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms">Terms of Service</Link></li>
              </ul>
            </div>
          </div>

          <div className="footer-company">
            <p>167-169 Great Portland Street,<br />
            5th Floor,<br />
            London,<br />
            United Kingdom<br />
            W1W 5PF<br />
            © 2025 Parcae Labs Ltd<br />
            All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;