import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, Typography, Paper, TextField, InputAdornment, Button, 
  FormControlLabel, Checkbox
} from '@mui/material';
import { 
  Search as SearchIcon, 
  KeyboardArrowDown as ArrowDownIcon,
  Check as CheckIcon,
  Close as CloseIcon 
} from '@mui/icons-material';
import { colors } from '../../admin/utils/constants';

const CustomFilterDropdown = ({ selected, setSelected, allOptions, label, renderItem, isCountry = false }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [localSelected, setLocalSelected] = useState(selected);
  const dropdownRef = useRef(null);
  
  // Initialize with all options selected
  useEffect(() => {
    // Sync localSelected with selected
    setLocalSelected(selected);
    
    // If both selected and allOptions are available but selected is empty,
    // select all options by default
    if (selected.length === 0 && allOptions.length > 0) {
      setSelected(allOptions);
      setLocalSelected(allOptions);
    }
  }, [selected, allOptions, setSelected]);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  
  // Handle checkbox changes
  const handleItemChange = (item) => {
    if (localSelected.includes(item)) {
      setLocalSelected(localSelected.filter(i => i !== item));
    } else {
      setLocalSelected([...localSelected, item]);
    }
  };
  
  // Filter options by search
  const filteredOptions = allOptions.filter(item => 
    item.toLowerCase().includes(search.toLowerCase())
  );
  
  // Handle select all - taking search filter into account
  const handleSelectAll = (checked) => {
    if (checked) {
      // If we have a search term, only select filtered options
      if (search.trim() !== '') {
        setLocalSelected(
          Array.from(new Set([...localSelected, ...filteredOptions]))
        );
      } else {
        // If no search term, select all options
        setLocalSelected(allOptions);
      }
    } else {
      // If unchecked, we need to handle differently based on search
      if (search.trim() !== '') {
        // If search is active, only deselect the filtered options
        setLocalSelected(
          localSelected.filter(item => !filteredOptions.includes(item))
        );
      } else {
        // If no search term, deselect all
        setLocalSelected([]);
      }
    }
  };
  
  // Apply changes
  const handleConfirm = () => {
    setSelected(localSelected);
    setOpen(false);
  };
  
  // Cancel changes
  const handleCancel = () => {
    setLocalSelected(selected);
    setOpen(false);
  };
  
  // Get display text for dropdown
  const getDisplayText = () => {
    if (selected.length === 0 || selected.length === allOptions.length) {
      return `All ${label.toLowerCase()}`;
    } else if (selected.length <= 2) {
      return selected.join(', ');
    } else {
      return `${selected[0]}, ${selected[1]} and ${selected.length - 2} more`;
    }
  };
  
  return (
    <Box ref={dropdownRef} sx={{ position: 'relative', width: '100%' }}>
      {/* Dropdown Trigger */}
      <Box 
        onClick={() => setOpen(!open)} 
        sx={{ 
          border: '1px solid',
          borderColor: open ? colors.primary : 'rgba(0, 0, 0, 0.23)',
          borderRadius: 1,
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          height: 40,
          boxSizing: 'border-box',
          '&:hover': {
            borderColor: open ? colors.primary : 'rgba(0, 0, 0, 0.87)',
          }
        }}
      >
        <Typography 
          variant="body2" 
          sx={{ 
            color: selected.length === 0 ? 'text.disabled' : 'text.primary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {getDisplayText()}
        </Typography>
        <ArrowDownIcon 
          fontSize="small" 
          sx={{ 
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s'
          }} 
        />
      </Box>
      
      {/* Dropdown Menu */}
      {open && (
        <Paper 
          sx={{ 
            position: 'absolute', 
            width: '100%', 
            mt: 0.5, 
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 1,
          }}
        >
          {/* Search */}
          <Box sx={{ p: 1.2, borderBottom: '1px solid #eee' }}>
            <TextField
              size="small"
              placeholder={`Search ${label.toLowerCase()}`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                sx: { borderRadius: 1 }
              }}
            />
          </Box>
          
          {/* Items List with Select All */}
          <Box sx={{ overflowY: 'auto', flex: 3, minHeight: 270, p: 1.5 }}>
            {/* Select All - inside the scrollable container */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={filteredOptions.length > 0 && filteredOptions.every(item => localSelected.includes(item))}
                  indeterminate={filteredOptions.some(item => localSelected.includes(item)) && !filteredOptions.every(item => localSelected.includes(item))}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  sx={{
                    color: colors.text.secondary,
                    '&.Mui-checked': { color: colors.primary },
                    '&.MuiCheckbox-indeterminate': { color: colors.primary }
                  }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                  {search.trim() !== '' 
                    ? `Select All Filtered (${filteredOptions.length})` 
                    : 'Select All'}
                </Typography>
              }
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '4px 8px',
                mb: 1,
                borderRadius: 1,
                backgroundColor: '#f8f9fa',
                '&:hover': { backgroundColor: `${colors.primary}10` }
              }}
            />
            {filteredOptions.length > 0 ? (
              filteredOptions.map(item => (
                <FormControlLabel
                  key={item}
                  control={
                    <Checkbox
                      checked={localSelected.includes(item)}
                      onChange={() => handleItemChange(item)}
                      size="small"
                      sx={{
                        color: colors.text.secondary,
                        '&.Mui-checked': { color: colors.primary },
                      }}
                    />
                  }
                  label={renderItem ? renderItem(item) : (
                    <Typography variant="body2">{item}</Typography>
                  )}
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    my: 0.25,
                    mx: 0,
                    '&:hover': { backgroundColor: `${colors.primary}10` },
                    borderRadius: 1,
                    padding: '4px 8px',
                    width: '100%'
                  }}
                />
              ))
            ) : (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
                No {label.toLowerCase()} found
              </Typography>
            )}
          </Box>
          
          {/* Buttons */}
          <Box sx={{ p: 1.2, borderTop: '1px solid #eee', display: 'flex', justifyContent: 'space-between' }}>
            <Button 
              size="small" 
              onClick={handleCancel}
              startIcon={<CloseIcon />}
              sx={{ color: colors.text.secondary }}
            >
              Cancel
            </Button>
            <Button 
              size="small" 
              variant="contained" 
              onClick={handleConfirm}
              startIcon={<CheckIcon />}
              sx={{ 
                background: colors.gradient,
                '&:hover': { background: colors.gradient, opacity: 0.9 }
              }}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default CustomFilterDropdown;
