import React from 'react';
import { Chip } from '@mui/material';
import { getStatusColor } from '../../utils/statusUtils';

const StatusChip = ({ status }) => {
  const statusStyle = getStatusColor(status);
  
  return (
    <Chip 
      label={status === 'never-purchased' 
        ? 'Never Purchased'
        : status.charAt(0).toUpperCase() + status.slice(1)} 
      sx={{ 
        color: statusStyle.color,
        bgcolor: statusStyle.bgColor,
        fontWeight: 500
      }}
      size="small"
    />
  );
};

export default StatusChip;
