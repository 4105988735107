import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

/**
 * Component for editing plain text version of the email template
 */
export const TextEditor = ({ value, onChange }) => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        This plain text version will be used as a fallback for email clients that don't support HTML.
      </Typography>
      <TextField
        fullWidth
        multiline
        minRows={12}
        value={value}
        onChange={onChange}
        placeholder="Enter plain text version of your email..."
        variant="outlined"
        InputProps={{
          sx: { fontFamily: 'monospace' }
        }}
      />
    </Box>
  );
};