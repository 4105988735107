import React from 'react';
import '../styles/CallToAction.css';

const CallToAction = () => {
  return (
    <section id="call-to-action" className="cta-section section">
      <div className="container">
        <div className="cta-card">
          <div className="cta-content">
            <h2>Ready to Transform Your Workflow?</h2>
            <p>Download Parcae today and start creating mind maps in seconds with the power of AI.</p>
            
            <div className="cta-buttons">
              <a 
                href="https://play.google.com/store/apps/details?id=com.parcae&hl=en_US" 
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download App
              </a>
            </div>
          </div>
          
          <div className="cta-decoration">
            <div className="cta-decoration-circle"></div>
            <div className="cta-decoration-circle"></div>
            <div className="cta-decoration-circle"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;