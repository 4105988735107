import React from 'react';
import '../styles/FeaturesSection.css';

// Import feature images
import featureNotes from '../assets/images/feature-notes.png';
import featureReminders from '../assets/images/feature-reminders.png';
import featureGenerate from '../assets/images/feature-generate.png';
import featureMasterSubjects from '../assets/images/feature-master-subjects.jpg';
import featurePresentations from '../assets/images/feature-presentations.png';

const FeaturesSection = () => {
  return (
    <section id="features" className="features-section section">
      <div className="container">
        <div className="features-badge animate-on-scroll">
          <span>What you'll get</span>
        </div>
        
        <div className="section-header">
          <h2 className="section-title animate-on-scroll">
            Parcae: Work and learn smarter, <span className="italic">not harder</span>.
          </h2>
        </div>

        <div className="features-grid">
          {/* Row 1 */}
          <div className="feature-card large animate-on-scroll delay-100">
            <div className="feature-card-content">
              <div className="feature-image-container">
                <img src={featureNotes} alt="Task management interface" className="feature-image" />
              </div>
              <h3 className="feature-title">Turn Notes into Knowledge</h3>
              <p className="feature-description">Stop re-reading the same information again and again. Make useful notes that you want to remember.</p>
            </div>
          </div>

          <div className="feature-card large animate-on-scroll delay-100">
            <div className="feature-card-content">
              <div className="feature-image-container">
                <img src={featureReminders} alt="Task management interface" className="feature-image" />
              </div>
              <h3 className="feature-title">Never Miss a Deadline or Detail</h3>
              <p className="feature-description">Parcae helps you capture, organize, and prioritize every detail, so you can focus on what truly matters. Set reminders, add notes, and stay in control.</p>
            </div>
          </div>

          {/* Row 2 */}
          <div className="feature-card large animate-on-scroll delay-200">
            <div className="feature-card-content">
              <div className="feature-image-container">
                <img src={featureGenerate} alt="Mind map example" className="feature-image" />
              </div>
              <h3 className="feature-title">Generate Ideas in Seconds</h3>
              <p className="feature-description">Struggling with a blank page? Just type your thoughts, and Parcae's AI will instantly structure them into a powerful mind map, revealing connections you might have missed.</p>
            </div>
          </div>

          <div className="feature-card large animate-on-scroll delay-300">
            <div className="feature-card-content">
              <div className="feature-image-container">
                <img src={featureMasterSubjects} alt="Learning notes" className="feature-image" />
              </div>
              <h3 className="feature-title">Master New Subjects Quickly</h3>
              <p className="feature-description">Transform complex information from lectures, articles, or research into clear, visual mind maps. Parcae helps you grasp concepts faster and retain knowledge longer.</p>
            </div>
          </div>
          <div className="feature-card large animate-on-scroll delay-300">
            <div className="feature-card-content">
              <div className="feature-image-container">
                <img src={featurePresentations} alt="Learning notes" className="feature-image" />
              </div>
              <h3 className="feature-title">Create Stunning Presentations Effortlessly</h3>
              <p className="feature-description">Turn your mind maps into impactful presentations with a single click. Parcae helps you communicate your ideas clearly and persuasively.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;