import React from 'react';
import '../styles/HowItWorks.css';

// Import step images
import parcaeLogo from '../assets/images/parcae-logo.png';
import addRequest from '../assets/images/add-request.png';
import manageGrow from '../assets/images/manage-grow.png';

const HowItWorks = () => {
  const steps = [
    {
      id: 1,
      number: '1',
      title: 'Install Parcae',
      description: 'Download the app and get up and running in minutes – start simplifying your workflow immediately',
      image: parcaeLogo
    },
    {
      id: 2,
      number: '2',
      title: 'Add Your Request',
      description: 'Type, paste, or dictate your ideas. Parcae instantly transforms your text into a mind map.',
      image: addRequest
    },
    {
      id: 3,
      number: '3',
      title: 'Manage & Grow',
      description: 'Customize, edit, add details, and share your maps. Stay in control and keep your projects on track.',
      image: manageGrow
    }
  ];

  return (
    <section id="how-it-works" className="how-it-works-section section">
      <div className="container">
        <div className="how-it-works-badge animate-on-scroll">
          <span>How it works</span>
        </div>
        
        <div className="how-it-works-header">
          <h2 className="how-it-works-title animate-on-scroll">
            Getting started is <span className="italic">simple</span>.
          </h2>
          <p className="how-it-works-subtitle animate-on-scroll delay-100">
            No more wasting hours on manual mind mapping. Parcae does it for you – quickly, visually, and effectively.
          </p>
        </div>

        <div className="steps-grid">
          {steps.map((step) => (
            <div key={step.id} className={`step-card animate-on-scroll delay-${step.id * 100}`}>
              <div className="step-number">{step.number}</div>
              <div className="step-image-container">
                <img src={step.image} alt={step.title} className="step-image" />
                <div className="step-image-overlay"></div>
              </div>
              <h3 className="step-title">{step.title}</h3>
              <p className="step-description">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;