import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box, 
  Chip 
} from '@mui/material';
import { Translate as TranslateIcon } from '@mui/icons-material';
import { colors } from '../../../admin/utils/constants';

/**
 * Dialog for selecting languages to translate the template into
 */
const TranslateDialog = ({ open, onClose, onTranslate, existingLanguages = ['en'] }) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [error, setError] = useState('');
  
  // All available languages
  const allLanguages = [
    { code: 'en-GB', name: 'English (UK)' },
    { code: 'en-AU', name: 'English (Australia)' },
    { code: 'en-CA', name: 'English (Canada)' },
    { code: 'en-NZ', name: 'English (New Zealand)' },
    { code: 'en-IE', name: 'English (Ireland)' },
    { code: 'en-ZA', name: 'English (South Africa)' },
    { code: 'es', name: 'Spanish (Spain)' },
    { code: 'es-MX', name: 'Spanish (Mexico)' },
    { code: 'es-AR', name: 'Spanish (Argentina)' },
    { code: 'es-CO', name: 'Spanish (Colombia)' },
    { code: 'es-CL', name: 'Spanish (Chile)' },
    { code: 'es-PE', name: 'Spanish (Peru)' },
    { code: 'fr', name: 'French' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'fr-BE', name: 'French (Belgium)' },
    { code: 'fr-CH', name: 'French (Switzerland)' },
    { code: 'de', name: 'German' },
    { code: 'de-AT', name: 'German (Austria)' },
    { code: 'de-CH', name: 'German (Switzerland)' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'ru', name: 'Russian' },
    { code: 'pt', name: 'Portuguese (Portugal)' },
    { code: 'pt-BR', name: 'Portuguese (Brazil)' },
    { code: 'ar', name: 'Arabic' }
  ];
  
  // Filter out languages that already exist in the template
  const availableLanguages = allLanguages.filter(
    lang => !existingLanguages.includes(lang.code)
  );
  
  const handleLanguageChange = (event) => {
    setSelectedLanguages(event.target.value);
    setError('');
  };
  
  const handleTranslate = () => {
    if (selectedLanguages.length === 0) {
      setError('Please select at least one language');
      return;
    }
    
    onTranslate(selectedLanguages);
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Translate Template</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Select the languages you want to translate this template into. 
          The original template will be preserved as the English version.
        </Typography>
        
        {availableLanguages.length === 0 ? (
          <Typography color="warning.main" variant="body2" sx={{ mb: 2 }}>
            All available languages have already been translated. No additional translations are available.
          </Typography>
        ) : (
          <FormControl fullWidth error={!!error}>
            <InputLabel id="language-select-label">Target Languages</InputLabel>
            <Select
              labelId="language-select-label"
              multiple
              value={selectedLanguages}
              onChange={handleLanguageChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const language = allLanguages.find(lang => lang.code === value);
                    return (
                      <Chip 
                        key={value} 
                        label={language ? language.name : value} 
                        size="small"
                      />
                    );
                  })}
                </Box>
              )}
            >
              {availableLanguages.length === 0 ? (
                <MenuItem disabled>
                  <em>No languages available for translation</em>
                </MenuItem>
              ) : (
                availableLanguages.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    {language.name}
                  </MenuItem>
                ))
              )}
            </Select>
            {error && <Typography color="error" variant="caption">{error}</Typography>}
          </FormControl>
        )}
        
        <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
          <strong>Note:</strong> Translations are generated using AI and may require review for accuracy, 
          especially for industry-specific terminology.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleTranslate} 
          variant="contained"
          startIcon={<TranslateIcon />}
          disabled={availableLanguages.length === 0 || selectedLanguages.length === 0}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none'
          }}
        >
          Translate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranslateDialog;