import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import { colors } from '../../admin/utils/constants';

const SaveSegmentDialog = ({
  open,
  onClose,
  segmentName,
  segmentDescription,
  onSegmentNameChange,
  onSegmentDescriptionChange,
  onSave,
  loading,
  filterSummary,
  userCount
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: 2 }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #eee', 
        fontWeight: 500, 
        py: 2 
      }}>
        Save User Segment
      </DialogTitle>
      <DialogContent sx={{ my: 2, minWidth: 400 }}>
        <DialogContentText sx={{ mb: 2, color: 'text.primary', fontSize: 14 }}>
          Create a segment to target specific users based on your current filter settings.
          This segment will include {userCount > 0 ? userCount : 0} users.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Segment Name"
          type="text"
          fullWidth
          variant="outlined"
          value={segmentName}
          onChange={onSegmentNameChange}
          InputProps={{
            sx: { borderRadius: 1 }
          }}
        />
        <TextField
          margin="dense"
          label="Description (optional)"
          type="text"
          fullWidth
          variant="outlined"
          value={segmentDescription}
          onChange={onSegmentDescriptionChange}
          InputProps={{
            sx: { borderRadius: 1 }
          }}
          sx={{ mt: 2 }}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontWeight: 'medium' }}>
            Filter Summary
          </Typography>
          <Paper variant="outlined" sx={{ p: 1.5, borderRadius: 1, bgcolor: '#f8f9fa' }}>
            {filterSummary}
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, borderTop: '1px solid #eee', pt: 2 }}>
        <Button 
          onClick={onClose} 
          sx={{ color: colors.text.secondary }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSave} 
          variant="contained"
          disabled={!segmentName.trim() || loading}
          sx={{
            background: colors.gradient,
            borderRadius: 2,
            '&:hover': { background: colors.gradient, opacity: 0.9 },
            '&.Mui-disabled': { opacity: 0.6 }
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Save Segment'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSegmentDialog;
