import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

/**
 * Protected Route Component
 * 
 * Restricts access to routes based on authentication state.
 * Handles direct access to admin routes with proper loading states
 * and cleans up loading indicators.
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components to render if authorized
 * @param {boolean} props.adminOnly - Whether the route requires admin privileges
 */
const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { isAuthenticated, isAdmin, loading } = useAuth();
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  
  // This effect ensures the scripts have time to load
  // before any authentication redirects happen
  useEffect(() => {
    // Clear any timeout from the index.html that might be checking for app load
    if (window.appLoadTimeout) {
      clearTimeout(window.appLoadTimeout);
    }
    
    // Clean up the initial loading indicator if it exists
    const initialLoader = document.getElementById('initial-loading');
    if (initialLoader && initialLoader.parentNode) {
      initialLoader.parentNode.removeChild(initialLoader);
    }
    
    // Give scripts some time to load before checking auth
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 500); // Reduced delay as we're more confident in our loading approach
    
    // Store the current path for potential recovery
    if (location.pathname.startsWith('/admin/')) {
      sessionStorage.setItem('lastAdminPath', location.pathname.substring(1));
    }
    
    return () => clearTimeout(timer);
  }, [location.pathname]);

  // Always show loading state first to ensure scripts have time to load
  // Only proceed with auth checks when isReady is true
  if (loading || !isReady) {
    return (
      <div className="loading" style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '1.2rem',
        color: '#6e8efb'
      }}>
        <div style={{
          width: '40px',
          height: '40px',
          border: '3px solid #f3f3f3',
          borderTop: '3px solid #6e8efb',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite',
          marginBottom: '20px'
        }} />
        <div>Loading Parcae Admin...</div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    console.log('Not authenticated, redirecting to login');
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  // Redirect to home if admin access is required but user is not an admin
  if (adminOnly && !isAdmin) {
    console.log('Not authorized as admin, redirecting to home');
    return <Navigate to="/" />;
  }

  // User is authenticated and authorized - render the protected content
  return children;
};

export default ProtectedRoute;