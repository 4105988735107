import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import '../styles/Navigation.css';
import Logo from './Logo';

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navigation ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container nav-container">
        <div className="nav-logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="nav-links desktop-nav">
          <HashLink to="/#how-it-works" className="nav-link">How It Works</HashLink>
          <HashLink to="/#features" className="nav-link">Features</HashLink>
          <HashLink to="/#testimonials" className="nav-link">Testimonials</HashLink>
          <HashLink to="/#faq" className="nav-link">FAQ</HashLink>
        </div>

        <div className="nav-cta desktop-nav">
          <a href="https://play.google.com/store/apps/details?id=com.parcae&hl=en_US" 
             className="btn btn-primary" 
             target="_blank" 
             rel="noopener noreferrer">
            Download
          </a>
        </div>

        {/* Mobile Navigation Toggle */}
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* Mobile Navigation Menu */}
        <div className={`mobile-nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="mobile-nav-links">
            <HashLink to="/#how-it-works" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>How It Works</HashLink>
            <HashLink to="/#features" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Features</HashLink>
            <HashLink to="/#testimonials" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Testimonials</HashLink>
            <HashLink to="/#faq" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>FAQ</HashLink>
            <a href="https://play.google.com/store/apps/details?id=com.parcae&hl=en_US" 
               className="btn btn-primary mobile-download" 
               target="_blank" 
               rel="noopener noreferrer"
               onClick={() => setIsMobileMenuOpen(false)}>
              Download
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;