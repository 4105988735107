import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import HowItWorks from '../components/HowItWorks';
import BrandLogos from '../components/BrandLogos';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import { initScrollAnimations } from '../utils/scrollAnimation';
import { useAuth } from '../context/AuthContext';

const HomePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin } = useAuth();

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    // Check if we need to redirect to an admin page after loading the homepage first
    const urlParams = new URLSearchParams(window.location.search);
    const isAdminRedirect = urlParams.get('admin_redirect') === '1';
    const targetAdminPath = sessionStorage.getItem('admin_redirect_target');
    
    if (isAdminRedirect && targetAdminPath && isAuthenticated && isAdmin) {
      console.log('Admin redirect in progress, navigating to:', targetAdminPath);
      // Clear the redirect info
      sessionStorage.removeItem('admin_redirect_target');
      // Use navigate instead of window.location to prevent full page reload
      setTimeout(() => {
        navigate(targetAdminPath);
      }, 100);
    }
    
    // Handle smooth scrolling for anchor links
    const handleAnchorClick = (e) => {
      const target = e.target;
      if (target.tagName === 'A' && target.getAttribute('href')?.startsWith('#')) {
        e.preventDefault();
        const id = target.getAttribute('href').slice(1);
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    document.addEventListener('click', handleAnchorClick);

    // Initialize scroll animations
    initScrollAnimations();

    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, [navigate, isAuthenticated, isAdmin]);

  return (
    <div className="app">
      <Navigation />
      <main>
        <HeroSection />
        <HowItWorks />
        <FeaturesSection />
        <BrandLogos />
        <Testimonials />
        {/* <DonationSection /> */}
        <FAQ />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;