import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Menu, 
  MenuItem, 
  ListItemText, 
  Divider, 
  Alert 
} from '@mui/material';
import {
  FunctionsRounded as VariablesIcon,
  Visibility,
  Code as CodeIcon,
  AutoFixHigh as AdjustIcon
} from '@mui/icons-material';
import ImageUploader from '../ImageUploader';

/**
 * Component for HTML content editing with variable insertion, image handling, and live preview
 */
export const HtmlEditor = ({ 
  value, 
  onChange, 
  onValidationChange, 
  onEnhanceTemplate, 
  adjusting,
  isDefaultLanguage = true, // Default to true for backward compatibility
  hasBeenAdjusted = false // New prop to track if template has been adjusted
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [htmlContent, setHtmlContent] = useState(value);
  const textFieldRef = useRef(null);
  const [imageValidation, setImageValidation] = useState({ 
    valid: true, 
    pendingUploads: false,
    hasErrors: false
  });
  
  // Update local state when prop changes
  useEffect(() => {
    setHtmlContent(value);
  }, [value]);
  
  // Handle content change
  const handleContentChange = (newContent) => {
    setHtmlContent(newContent);
    onChange(newContent);
  };
  
  // Track cursor position in the text field
  const handleTextFieldChange = (e) => {
    handleContentChange(e.target.value);
  };
  
  // Insert text at cursor position
  const insertAtCursor = (textToInsert) => {
    if (!textFieldRef.current) return;
    
    const textarea = textFieldRef.current.querySelector('textarea');
    if (!textarea) return;
    
    // Get current cursor position or selection
    const start = textarea.selectionStart || 0;
    const end = textarea.selectionEnd || 0;
    
    // Insert the text at cursor position
    const newContent = 
      htmlContent.substring(0, start) + 
      textToInsert + 
      htmlContent.substring(end);
    
    // Update content
    handleContentChange(newContent);
    
    // Save the new cursor position for after the render
    const newPosition = start + textToInsert.length;
    
    // Need to use setTimeout to set cursor position after React renders
    setTimeout(() => {
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(newPosition, newPosition);
      }
    }, 0);
  };
  
  // Custom variable insertion that preserves cursor position
  const handleInsertVariable = (variable) => {
    const variableText = `{{${variable}}}`;
    insertAtCursor(variableText);
  };
  
  // Handle image validation status
  const handleImageValidation = (validationStatus) => {
    setImageValidation(validationStatus);
    if (onValidationChange) {
      onValidationChange(validationStatus);
    }
  };
  
  return (
    <Box>
      {/* Image uploader - only show for default language templates */}
      {isDefaultLanguage && (
        <ImageUploader 
          htmlContent={htmlContent}
          onChange={handleContentChange}
          onValidationChange={handleImageValidation}
        />
      )}
      
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Box display="flex" alignItems="center">
          {/* Only show Variable picker for default language */}
          {isDefaultLanguage && (
            <VariablePicker onSelectVariable={handleInsertVariable} />
          )}
          
          {/* Only show Adjust button for default language */}
          {onEnhanceTemplate && isDefaultLanguage && (
            <>
              {!hasBeenAdjusted ? (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AdjustIcon />}
                  onClick={onEnhanceTemplate}
                  disabled={adjusting || !htmlContent}
                  sx={{ ml: 1 }}
                >
                  {adjusting ? 'Optimizing...' : 'Adjust for Email Clients'}
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    color="success"
                    startIcon={<AdjustIcon />}
                    disabled={true}
                    sx={{ ml: 1 }}
                  >
                    Adjusted ✓
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    onClick={onEnhanceTemplate}
                    disabled={adjusting || !htmlContent}
                    sx={{ ml: 1 }}
                  >
                    Adjust Again
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
        
        <Button
          variant="outlined"
          size="small"
          startIcon={previewVisible ? <CodeIcon /> : <Visibility />}
          onClick={() => setPreviewVisible(!previewVisible)}
        >
          {previewVisible ? 'Show Editor' : 'Show Preview'}
        </Button>
      </Box>
      
      {/* Display validation status if there are issues */}
      {(imageValidation.pendingUploads || imageValidation.hasErrors) && (
        <Alert 
          severity={imageValidation.hasErrors ? "error" : "warning"} 
          variant="outlined"
          sx={{ mb: 2 }}
        >
          {imageValidation.hasErrors 
            ? "There are errors with image uploads. Please fix them before saving." 
            : "Some images need to be uploaded before saving."}
        </Alert>
      )}
      
      {!previewVisible ? (
        <>
          <TextField
            ref={textFieldRef}
            multiline
            fullWidth
            minRows={20}
            variant="outlined"
            value={htmlContent}
            onChange={handleTextFieldChange}
            placeholder="Paste or enter your HTML template here..."
            InputProps={{
              sx: { 
                fontFamily: 'monospace',
                fontSize: '14px'
              }
            }}
          />
          
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
            Use variables to personalize your email. Insert them using the Variable button above.
          </Typography>
        </>
      ) : (
        <Box 
          sx={{ 
            border: '1px solid rgba(0, 0, 0, 0.23)', 
            borderRadius: 1, 
            p: 2,
            height: '500px',
            overflow: 'auto',
            bgcolor: '#fff'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Box>
      )}
    </Box>
  );
};

/**
 * Component for selecting variables to insert into the template
 */
const VariablePicker = ({ onSelectVariable }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const variables = {
    'User Variables': [
      { name: 'user.id', description: 'User ID' },
      { name: 'user.name', description: 'User full name' },
      { name: 'user.email', description: 'User email address' },
      { name: 'user.balance', description: 'Current account balance' },
      { name: 'user.totalSpent', description: 'Total amount spent' },
      { name: 'user.formatted_created_date', description: 'Account creation date (formatted)' },
      { name: 'user.formatted_subscription_expiry', description: 'Subscription expiry date (formatted)' }
    ],
    'Campaign Variables': [
      { name: 'campaign.id', description: 'Campaign ID' },
      { name: 'campaign.name', description: 'Campaign name' },
      { name: 'campaign.subject', description: 'Campaign subject line' }
    ],
    'System Variables': [
      { name: 'current_date', description: 'Current date (formatted)' },
      { name: 'unsubscribe_link', description: 'Unsubscribe link' },
      { name: 'preferences_link', description: 'Email preferences link' }
    ]
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSelectVariable = (variable) => {
    onSelectVariable(variable);
    handleClose();
  };
  
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<VariablesIcon />}
        onClick={handleClick}
        size="small"
        sx={{ mr: 1 }}
      >
        Insert Variable
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { maxHeight: 300, width: 320 } }}
      >
        {Object.entries(variables).map(([category, vars]) => (
          <Box key={category}>
            <Typography variant="caption" sx={{ px: 2, py: 1, display: 'block', color: 'text.secondary' }}>
              {category}
            </Typography>
            {vars.map((variable) => (
              <MenuItem key={variable.name} onClick={() => handleSelectVariable(variable.name)}>
                <ListItemText 
                  primary={variable.name} 
                  secondary={variable.description}
                  primaryTypographyProps={{
                    fontFamily: 'monospace',
                    fontSize: '0.9rem'
                  }}
                />
              </MenuItem>
            ))}
            <Divider />
          </Box>
        ))}
      </Menu>
    </>
  );
};