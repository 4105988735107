import apiService from './api';

export const getUsersService = {
  getUsers: async (page, rowsPerPage, orderBy, order, filters) => {
    try {
      const params = {
        page: page + 1, // API uses 1-based indexing
        limit: rowsPerPage,
        sort: orderBy,
        order: order,
        filters
      };
      
      const result = await apiService.admin.getUsers(params);
      return result;
    } catch (err) {
      console.error("Error fetching users:", err);
      throw err;
    }
  },
  
  getUserMetadata: async () => {
    try {
      const result = await apiService.admin.getUserMetadata();
      return result;
    } catch (err) {
      console.error("Error fetching metadata:", err);
      throw err;
    }
  },

  createSegment: async (segmentData) => {
    try {
      const result = await apiService.admin.createSegment(segmentData);
      return result;
    } catch (err) {
      console.error("Error creating segment:", err);
      throw err;
    }
  }
};
