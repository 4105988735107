import React, { useState } from 'react';
import '../styles/FAQ.css';

const FAQ = () => {
  const faqs = [
    {
      id: 1,
      question: "What is Parcae?",
      answer: "Parcae is an AI-powered mobile mind mapping app that automatically converts text into organized visual mind maps. It helps users organize thoughts, plan projects, and learn more efficiently through visual representations of information."
    },
    {
      id: 2,
      question: "How does the AI technology work?",
      answer: "Our AI analyzes your text input, identifies key concepts and relationships, and automatically structures them into a coherent mind map. The technology uses natural language processing and machine learning to understand context and create meaningful visual representations."
    },
    {
      id: 3,
      question: "Is Parcae available for iOS and Android?",
      answer: "Parcae is currently available for Android devices on the Google Play Store. An iOS version is in development and will be released soon. Join our waitlist to be notified when it becomes available."
    },
    {
      id: 4,
      question: "Can I collaborate with others on mind maps?",
      answer: "Yes, Parcae supports real-time collaboration. You can share your mind maps with teammates, friends, or classmates and work together on them simultaneously. All changes are synced in real-time across all devices."
    },
    {
      id: 5,
      question: "What file formats can I export my mind maps to?",
      answer: "Parcae allows you to export your mind maps in multiple formats including PDF, PNG, JPEG, and editable text formats. This makes it easy to share your mind maps or include them in presentations, documents, or other materials."
    },
    {
      id: 6,
      question: "Is there a free version available?",
      answer: "Yes, Parcae offers a free version with core features. We also offer premium subscriptions with advanced features, higher usage limits, and additional customization options."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq-section section">
      <div className="container">
        <div className="section-header">
          <h2 className="section-title animate-on-scroll">Frequently Asked Questions</h2>
          <p className="section-subtitle animate-on-scroll delay-100">Find answers to common questions about Parcae</p>
        </div>

        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div 
              key={faq.id} 
              className={`faq-item animate-on-scroll delay-${faq.id * 100}`}
            >
              <div 
                className={`faq-question-container ${openIndex === index ? 'active' : ''}`}
                onClick={() => toggleFAQ(index)}
              >
                <div className="faq-question">
                  <h3>{faq.question}</h3>
                  <span className="faq-icon">{openIndex === index ? '−' : '+'}</span>
                </div>
                <div className={`faq-answer ${openIndex === index ? 'open' : ''}`}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;