import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Theme
import theme from './utils/theme';

// Layout
import Layout from './components/Layout';

// Pages
import Dashboard from './pages/Dashboard';
import UsersPage from '../pages/UsersPage';
import EmailCampaignsPage from '../pages/EmailCampaignsPage';
import EmailTemplatesPage from '../pages/EmailTemplatesPage';
import SupportPage from '../pages/SupportPage';

// Protected route wrapper
import ProtectedRoute from '../components/ProtectedRoute';

const AdminApp = () => {
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* Default redirect to dashboard */}
        <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
        
        <Route
          path="dashboard"
          element={
            <ProtectedRoute adminOnly={true}>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute adminOnly={true}>
              <Layout>
                <UsersPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="email-campaigns"
          element={
            <ProtectedRoute adminOnly={true}>
              <Layout>
                <EmailCampaignsPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="email-templates"
          element={
            <ProtectedRoute adminOnly={true}>
              <Layout>
                <EmailTemplatesPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="support"
          element={
            <ProtectedRoute adminOnly={true}>
              <Layout>
                <SupportPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        
        {/* Catch any other paths and redirect to dashboard */}
        <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default AdminApp;