import { useState, useEffect } from 'react';

export default function usePagination(initialPage = 0, initialRowsPerPage = 10, onPageChange) {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page, rowsPerPage);
    }
  }, [page, rowsPerPage, onPageChange]);

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  };
}
