export const apiMockUsers = [
  {
    id: "user123",
    name: "John Doe",
    email: "john.doe@example.com",
    registrationDate: "2023-01-15T10:30:00Z",
    lastAiAttemptDate: "2023-06-20T14:45:22Z",
    country: "US",
    language: "en",
    totalSpent: 250,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user124",
    name: "Jane Smith",
    email: "jane.smith@example.com",
    registrationDate: "2023-02-10T11:15:00Z",
    lastAiAttemptDate: "2023-06-18T09:30:10Z",
    country: "UK",
    language: "en",
    totalSpent: 350,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user125",
    name: "Alice Johnson",
    email: "alice.j@example.com",
    registrationDate: "2023-03-05T14:20:00Z",
    lastAiAttemptDate: "2023-06-15T16:22:45Z",
    country: "US",
    language: "en",
    totalSpent: 150,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user126",
    name: "Robert Brown",
    email: "robert.b@example.com",
    registrationDate: "2023-01-20T09:45:00Z",
    lastAiAttemptDate: "2023-05-10T11:33:18Z",
    country: "DE",
    language: "de",
    totalSpent: 0,
    subscriptionStatus: "expired"
  },
  {
    id: "user127",
    name: "Emma Wilson",
    email: "emma.w@example.com",
    registrationDate: "2023-04-12T13:50:00Z",
    lastAiAttemptDate: "2023-06-19T10:15:30Z",
    country: "FR",
    language: "fr",
    totalSpent: 420,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user128",
    name: "Michael Garcia",
    email: "michael.g@example.com",
    registrationDate: "2023-02-28T16:05:00Z",
    lastAiAttemptDate: "2023-06-17T13:42:05Z",
    country: "ES",
    language: "es",
    totalSpent: 280,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user129",
    name: "Sophia Martinez",
    email: "sophia.m@example.com",
    registrationDate: "2023-05-08T10:20:00Z",
    lastAiAttemptDate: "2023-06-16T15:18:40Z",
    country: "ES",
    language: "es",
    totalSpent: 190,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user130",
    name: "Daniel Taylor",
    email: "daniel.t@example.com",
    registrationDate: "2023-03-17T11:35:00Z",
    lastAiAttemptDate: "2023-05-22T09:10:15Z",
    country: "US",
    language: "en",
    totalSpent: 0,
    subscriptionStatus: "never-purchased"
  },
  {
    id: "user131",
    name: "Olivia Anderson",
    email: "olivia.a@example.com",
    registrationDate: "2023-04-25T14:40:00Z",
    lastAiAttemptDate: "2023-06-10T14:25:50Z",
    country: "UK",
    language: "en",
    totalSpent: 310,
    subscriptionStatus: "subscribed"
  },
  {
    id: "user132",
    name: "William Thomas",
    email: "william.t@example.com",
    registrationDate: "2023-01-30T09:15:00Z",
    lastAiAttemptDate: "2023-06-05T11:50:30Z",
    country: "DE",
    language: "de",
    totalSpent: 180,
    subscriptionStatus: "expired"
  },
  {
    id: "user133",
    name: "Ava Hernandez",
    email: "ava.h@example.com",
    registrationDate: "2023-05-15T13:25:00Z",
    lastAiAttemptDate: "2023-06-12T16:35:20Z",
    country: "US",
    language: "es",
    totalSpent: 90,
    subscriptionStatus: "never-purchased"
  },
  {
    id: "user134",
    name: "James Moore",
    email: "james.m@example.com",
    registrationDate: "2023-02-14T15:50:00Z",
    lastAiAttemptDate: "2023-06-08T10:40:55Z",
    country: "UK",
    language: "en",
    totalSpent: 270,
    subscriptionStatus: "subscribed"
  }
];

export const mockUsers = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    registrationDate: "2023-01-15",
    lastLogin: "2023-12-20",
    country: "US",
    language: "English-US",
    totalSpent: 350,
    topics: ["Mind Mapping", "AI"],
    subscriptionStatus: "subscribed",
    mapsCreated: 28
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane.smith@example.com",
    registrationDate: "2023-02-22",
    lastLogin: "2023-12-15",
    country: "FR",
    language: "French-FR",
    totalSpent: 120,
    topics: ["Productivity", "Learning"],
    subscriptionStatus: "subscribed",
    mapsCreated: 42
  },
  {
    id: 3,
    name: "Robert Johnson",
    email: "robert.j@example.com",
    registrationDate: "2023-03-10",
    lastLogin: "2023-11-30",
    country: "UK",
    language: "English-UK",
    totalSpent: 0,
    topics: ["Note Taking"],
    subscriptionStatus: "never-purchased",
    mapsCreated: 15
  },
  {
    id: 4,
    name: "Maria Garcia",
    email: "maria.g@example.com",
    registrationDate: "2023-04-05",
    lastLogin: "2023-12-18",
    country: "MX",
    language: "Spanish-MX",
    totalSpent: 420,
    topics: ["AI", "Productivity", "Learning"],
    subscriptionStatus: "subscribed",
    mapsCreated: 56
  },
  {
    id: 5,
    name: "Thomas Weber",
    email: "t.weber@example.com",
    registrationDate: "2023-05-20",
    lastLogin: "2023-08-19",
    country: "DE",
    language: "German-DE",
    totalSpent: 210,
    topics: ["Note Taking", "Mind Mapping"],
    subscriptionStatus: "expired",
    mapsCreated: 34
  }
];

export const mockEmails = [
  {
    id: 1,
    userId: 1,
    from: "john.doe@example.com",
    subject: "Question about premium features",
    body: "Hi, I've recently upgraded to premium and I'm curious about the AI-powered suggestions feature. How can I access it?",
    date: "2023-12-10T14:30:00",
    read: true
  },
  {
    id: 2,
    userId: 2,
    from: "jane.smith@example.com",
    subject: "Feature request for collaboration",
    body: "Hello, I love using Parcae for my mind maps! I'd like to suggest adding real-time collaboration features so I can work with my team on the same map. Would this be possible in a future update?",
    date: "2023-12-15T09:45:00",
    read: false
  },
  {
    id: 3,
    userId: 4,
    from: "maria.g@example.com",
    subject: "Technical issue with map export",
    body: "I'm experiencing a technical issue with exporting my mind maps to PDF. The process seems to get stuck and I can't download the file. I'm using the latest app version on iOS.",
    date: "2023-12-18T11:20:00",
    read: false
  }
];

export const mockCampaigns = [
  {
    id: 1,
    name: "Welcome Campaign",
    subject: "Welcome to Parcae - Your AI-Powered Mind Mapping Journey Begins!",
    content: "<h1>Welcome to Parcae!</h1><p>We're excited to have you join our community of innovative thinkers and creators. With Parcae's AI-powered mind mapping, you can organize your thoughts more efficiently than ever before.</p><p>Here are a few tips to get started:</p><ul><li>Create your first mind map by tapping the + button</li><li>Use voice commands for hands-free mapping</li><li>Try the AI suggestions to expand your ideas</li></ul>",
    sentDate: "2023-11-01",
    audience: {
      language: ["en"],
      topics: ["Mind Mapping", "AI"],
      minSpent: 0
    },
    stats: {
      sent: 1250,
      opened: 840,
      clicked: 320
    }
  },
  {
    id: 2,
    name: "Premium Features Update",
    subject: "New AI Features Available for Parcae Premium Users",
    content: "<h1>Enhance Your Mind Mapping with Our Latest AI Features</h1><p>We've added some exciting new capabilities to Parcae Premium:</p><ul><li>Advanced AI suggestions based on your topic</li><li>Voice-to-mind-map improvement with 95% accuracy</li><li>Theme customization with smart color selection</li><li>Expanded export options including Notion integration</li></ul><p>Log in today to try these new features!</p>",
    sentDate: "2023-12-01",
    audience: {
      language: ["en", "fr", "es"],
      topics: ["Productivity", "AI"],
      minSpent: 100
    },
    stats: {
      sent: 850,
      opened: 620,
      clicked: 210
    }
  }
];

export const mockEmailTemplates = [
  {
    id: "template1",
    name: "Welcome Email",
    description: "Sent to new users after registration",
    defaultContent: {
      language: "en",
      subject: "Welcome to Parcae, {{user.name}}!",
      htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #a777e3;">Welcome to Parcae, {{user.name}}!</h1>
        <p>Thank you for joining Parcae. We're excited to have you on board!</p>
        <p>With Parcae, you can:</p>
        <ul>
          <li>Create beautiful mind maps</li>
          <li>Organize your thoughts efficiently</li>
          <li>Collaborate with your team</li>
        </ul>
        <p>Your account was created on {{user.formatted_created_date}}.</p>
        <div style="margin-top: 30px; text-align: center;">
          <a href="#" style="background-color: #a777e3; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px;">Get Started</a>
        </div>
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
          If you didn't create this account, please <a href="#">contact support</a>.<br>
          <a href="{{unsubscribe_link}}">Unsubscribe</a> | <a href="{{preferences_link}}">Email Preferences</a>
        </p>
      </div>`,
      textContent: "Welcome to Parcae, {{user.name}}!\n\nThank you for joining Parcae. We're excited to have you on board!\n\nWith Parcae, you can:\n- Create beautiful mind maps\n- Organize your thoughts efficiently\n- Collaborate with your team\n\nYour account was created on {{user.formatted_created_date}}.\n\nIf you didn't create this account, please contact support.\n\nUnsubscribe: {{unsubscribe_link}}\nEmail Preferences: {{preferences_link}}"
    },
    localizations: {
      "es": {
        language: "es",
        subject: "¡Bienvenido a Parcae, {{user.name}}!",
        htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
          <h1 style="color: #a777e3;">¡Bienvenido a Parcae, {{user.name}}!</h1>
          <p>Gracias por unirte a Parcae. ¡Estamos encantados de tenerte a bordo!</p>
          <p>Con Parcae, puedes:</p>
          <ul>
            <li>Crear hermosos mapas mentales</li>
            <li>Organizar tus pensamientos de manera eficiente</li>
            <li>Colaborar con tu equipo</li>
          </ul>
          <p>Tu cuenta fue creada el {{user.formatted_created_date}}.</p>
          <div style="margin-top: 30px; text-align: center;">
            <a href="#" style="background-color: #a777e3; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px;">Comenzar</a>
          </div>
          <p style="margin-top: 30px; font-size: 12px; color: #666;">
            Si no creaste esta cuenta, por favor <a href="#">contacta a soporte</a>.<br>
            <a href="{{unsubscribe_link}}">Cancelar suscripción</a> | <a href="{{preferences_link}}">Preferencias de correo</a>
          </p>
        </div>`,
        textContent: "¡Bienvenido a Parcae, {{user.name}}!\n\nGracias por unirte a Parcae. ¡Estamos encantados de tenerte a bordo!\n\nCon Parcae, puedes:\n- Crear hermosos mapas mentales\n- Organizar tus pensamientos de manera eficiente\n- Colaborar con tu equipo\n\nTu cuenta fue creada el {{user.formatted_created_date}}.\n\nSi no creaste esta cuenta, por favor contacta a soporte.\n\nCancelar suscripción: {{unsubscribe_link}}\nPreferencias de correo: {{preferences_link}}"
      }
    },
    createdAt: "2023-11-15T10:30:00Z",
    updatedAt: "2023-12-01T14:45:00Z",
    variables: ["user.name", "user.formatted_created_date", "unsubscribe_link", "preferences_link"]
  },
  {
    id: "template2",
    name: "Premium Upgrade Confirmation",
    description: "Sent when a user upgrades to Premium",
    defaultContent: {
      language: "en",
      subject: "Your Parcae Premium Upgrade is Complete!",
      htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #a777e3;">Premium Upgrade Confirmed!</h1>
        <p>Hello {{user.name}},</p>
        <p>Thank you for upgrading to Parcae Premium! Your account has been successfully upgraded, and you now have access to all premium features.</p>
        <p>Your premium subscription will renew on {{user.formatted_subscription_expiry}}.</p>
        <h2 style="color: #6e8efb; margin-top: 20px;">Your Premium Benefits:</h2>
        <ul>
          <li>Advanced AI mind map generation</li>
          <li>Unlimited mind maps</li>
          <li>Premium templates</li>
          <li>Priority support</li>
        </ul>
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
          If you have any questions, please <a href="#">contact support</a>.<br>
          <a href="{{unsubscribe_link}}">Unsubscribe</a> | <a href="{{preferences_link}}">Email Preferences</a>
        </p>
      </div>`,
      textContent: "Premium Upgrade Confirmed!\n\nHello {{user.name}},\n\nThank you for upgrading to Parcae Premium! Your account has been successfully upgraded, and you now have access to all premium features.\n\nYour premium subscription will renew on {{user.formatted_subscription_expiry}}.\n\nYour Premium Benefits:\n- Advanced AI mind map generation\n- Unlimited mind maps\n- Premium templates\n- Priority support\n\nIf you have any questions, please contact support.\n\nUnsubscribe: {{unsubscribe_link}}\nEmail Preferences: {{preferences_link}}"
    },
    localizations: {},
    createdAt: "2023-10-20T09:15:00Z",
    updatedAt: "2023-12-05T11:30:00Z",
    variables: ["user.name", "user.formatted_subscription_expiry", "unsubscribe_link", "preferences_link"]
  },
  {
    id: "template3",
    name: "Monthly Newsletter",
    description: "Monthly newsletter with updates and tips",
    defaultContent: {
      language: "en",
      subject: "Parcae Monthly: New Features and Tips for {{current_date}}",
      htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #a777e3;">Parcae Monthly Update</h1>
        <p>Hello {{user.name}},</p>
        <p>Here's your monthly update on what's new at Parcae:</p>
        <h2 style="color: #6e8efb; margin-top: 20px;">New Features</h2>
        <ul>
          <li>Enhanced AI suggestions</li>
          <li>New collaboration tools</li>
          <li>Improved export options</li>
        </ul>
        <h2 style="color: #6e8efb; margin-top: 20px;">Tips & Tricks</h2>
        <p>Did you know you can use keyboard shortcuts to speed up your mind mapping? Try pressing 'T' to add a new topic!</p>
        <div style="margin-top: 30px; text-align: center;">
          <a href="#" style="background-color: #a777e3; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px;">Explore New Features</a>
        </div>
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
          <a href="{{unsubscribe_link}}">Unsubscribe</a> | <a href="{{preferences_link}}">Email Preferences</a>
        </p>
      </div>`,
      textContent: "Parcae Monthly Update\n\nHello {{user.name}},\n\nHere's your monthly update on what's new at Parcae:\n\nNew Features:\n- Enhanced AI suggestions\n- New collaboration tools\n- Improved export options\n\nTips & Tricks:\nDid you know you can use keyboard shortcuts to speed up your mind mapping? Try pressing 'T' to add a new topic!\n\nUnsubscribe: {{unsubscribe_link}}\nEmail Preferences: {{preferences_link}}"
    },
    localizations: {
      "fr": {
        language: "fr",
        subject: "Parcae Mensuel: Nouvelles fonctionnalités et conseils pour {{current_date}}",
        htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
          <h1 style="color: #a777e3;">Mise à jour mensuelle de Parcae</h1>
          <p>Bonjour {{user.name}},</p>
          <p>Voici votre mise à jour mensuelle sur les nouveautés de Parcae :</p>
          <h2 style="color: #6e8efb; margin-top: 20px;">Nouvelles fonctionnalités</h2>
          <ul>
            <li>Suggestions IA améliorées</li>
            <li>Nouveaux outils de collaboration</li>
            <li>Options d'exportation améliorées</li>
          </ul>
          <h2 style="color: #6e8efb; margin-top: 20px;">Astuces et conseils</h2>
          <p>Saviez-vous que vous pouvez utiliser des raccourcis clavier pour accélérer votre cartographie mentale ? Essayez d'appuyer sur 'T' pour ajouter un nouveau sujet !</p>
          <div style="margin-top: 30px; text-align: center;">
            <a href="#" style="background-color: #a777e3; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px;">Explorer les nouvelles fonctionnalités</a>
          </div>
          <p style="margin-top: 30px; font-size: 12px; color: #666;">
            <a href="{{unsubscribe_link}}">Se désabonner</a> | <a href="{{preferences_link}}">Préférences d'e-mail</a>
          </p>
        </div>`,
        textContent: "Mise à jour mensuelle de Parcae\n\nBonjour {{user.name}},\n\nVoici votre mise à jour mensuelle sur les nouveautés de Parcae :\n\nNouvelles fonctionnalités :\n- Suggestions IA améliorées\n- Nouveaux outils de collaboration\n- Options d'exportation améliorées\n\nAstuces et conseils :\nSaviez-vous que vous pouvez utiliser des raccourcis clavier pour accélérer votre cartographie mentale ? Essayez d'appuyer sur 'T' pour ajouter un nouveau sujet !\n\nSe désabonner : {{unsubscribe_link}}\nPréférences d'e-mail : {{preferences_link}}"
      },
      "de": {
        language: "de",
        subject: "Parcae Monatlich: Neue Funktionen und Tipps für {{current_date}}",
        htmlContent: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
          <h1 style="color: #a777e3;">Parcae Monatsupdate</h1>
          <p>Hallo {{user.name}},</p>
          <p>Hier ist Ihr monatliches Update zu den Neuigkeiten bei Parcae:</p>
          <h2 style="color: #6e8efb; margin-top: 20px;">Neue Funktionen</h2>
          <ul>
            <li>Verbesserte KI-Vorschläge</li>
            <li>Neue Kollaborationswerkzeuge</li>
            <li>Verbesserte Exportoptionen</li>
          </ul>
          <h2 style="color: #6e8efb; margin-top: 20px;">Tipps & Tricks</h2>
          <p>Wussten Sie, dass Sie Tastaturkürzel verwenden können, um Ihr Mind-Mapping zu beschleunigen? Drücken Sie 'T', um ein neues Thema hinzuzufügen!</p>
          <div style="margin-top: 30px; text-align: center;">
            <a href="#" style="background-color: #a777e3; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px;">Neue Funktionen erkunden</a>
          </div>
          <p style="margin-top: 30px; font-size: 12px; color: #666;">
            <a href="{{unsubscribe_link}}">Abmelden</a> | <a href="{{preferences_link}}">E-Mail-Einstellungen</a>
          </p>
        </div>`,
        textContent: "Parcae Monatsupdate\n\nHallo {{user.name}},\n\nHier ist Ihr monatliches Update zu den Neuigkeiten bei Parcae:\n\nNeue Funktionen:\n- Verbesserte KI-Vorschläge\n- Neue Kollaborationswerkzeuge\n- Verbesserte Exportoptionen\n\nTipps & Tricks:\nWussten Sie, dass Sie Tastaturkürzel verwenden können, um Ihr Mind-Mapping zu beschleunigen? Drücken Sie 'T', um ein neues Thema hinzuzufügen!\n\nAbmelden: {{unsubscribe_link}}\nE-Mail-Einstellungen: {{preferences_link}}"
      }
    },
    createdAt: "2023-09-10T16:20:00Z",
    updatedAt: "2023-12-08T13:45:00Z",
    variables: ["user.name", "current_date", "unsubscribe_link", "preferences_link"]
  }
];

export const mockStats = {
  users: {
    total: 3200,
    active: 2800,
    premium: 1200,
    free: 2000,
    newThisMonth: 120
  },
  mindMaps: {
    total: 45600,
    createdThisMonth: 4200,
    averagePerUser: 14.3,
    withVoice: 12400
  },
  engagement: {
    dailyActiveUsers: 1850,
    weeklyActiveUsers: 2400,
    monthlyActiveUsers: 2800,
    averageSessionTime: 18.5 // minutes
  },
  revenue: {
    total: 128500,
    thisMonth: 12400,
    lastMonth: 10800,
    projectedNextMonth: 13500
  }
};