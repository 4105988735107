import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';

const LoginPage = () => {
  const { loginWithGoogle, loading, isAuthenticated, isAdmin } = useAuth();
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get redirect path from location state
  const from = location.state?.from || '/admin/dashboard';
  
  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      // If trying to access admin route but not an admin, redirect to home
      if (from.startsWith('/admin/') && !isAdmin) {
        navigate('/');
      } else {
        navigate(from);
      }
    }
  }, [isAuthenticated, isAdmin, navigate, from]);

  const handleGoogleSuccess = async (credentialResponse) => {
    setProcessing(true);
    setError('');
    
    try {
      // Store the intended destination for post-login redirect
      sessionStorage.setItem('auth_redirect', from);
      
      const result = await loginWithGoogle(credentialResponse.credential);
      
      if (!result.success) {
        setError(result.message || 'Login failed');
      }
    } catch (err) {
      setError('Login failed. Please try again.');
      console.error('Login error:', err);
    } finally {
      setProcessing(false);
    }
  };
  
  const handleGoogleError = () => {
    setError('Google login failed. Please try again.');
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Sign In</h1>
        <p>Use your Google account to sign in to Parcae.</p>
        
        <div className="google-login-container">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleError}
            useOneTap
            disabled={loading || processing}
          />
        </div>
        
        {error && <div className="error-message">{error}</div>}
        {(loading || processing) && <div className="loading">Authenticating...</div>}
      </div>
    </div>
  );
};

export default LoginPage;